import React from "react";
import Locale from "../../utils/locale";
import {branchedComponent} from "../../utils/branch";

const NYCAdmissionTicketsInfo = () => {
  return <></>;
  // return <div className="row center-xs middle-xs">
  //   <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6 m-mb4">
  //     <div className="text-left mb6 content">
  //         <div className="">
  //             <p className="">
  //               In partnership with Hip Hop 50, $1 of each ticket sale goes to support the Universal Hip Hop Museum.
  //             </p>
  //         </div>
  //     </div>
  //   </div>
  // </div>
}


const STOAdmissionTicketsInfoEn = () => <></>;

const STOAdmissionTicketsInfoSv = () => <></>;


export const AdmissionTicketsInfo = branchedComponent({
  stockholm: Locale.getShortLocale() === 'en' ? STOAdmissionTicketsInfoEn : STOAdmissionTicketsInfoSv,
  new_york: NYCAdmissionTicketsInfo
});


export const isAdmissionTicketsPage = ({app}) => {
  const apps = [
    "default",
    "American_Express_Platinum_David_LaChapelle",
    "David-LaChapelle",
    "chasemarriottbonvoy_boundless_hiphop",
    "hiphop",
  ];
  return apps.includes(app);
};
