import React from "react";
import {ReactComponent as Logo} from "../images/logo.svg";
import {Link} from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import Locale from "../utils/locale";

const termsEn = `
### Customer Terms and Conditions

These Terms and Conditions of Purchase apply between Fotografiska and you (henceforth referred to as the Customer) as a purchaser of tickets and/or related goods.

##### Customer’s obligations when ordering

It is your duty as a customer to check that your shopping cart has the correct content before completing your order. You are also required to enter the correct details for us to guarantee delivery of your order.

##### Personal data

The personal data you disclose is processed with respect, and in compliance with the General Data Protection Regulation (Regulation (EU) No 2016/679 of the European Parliament and of the Council, GDPR). Read more about our processing procedures in our Personal Data Policy.

##### Prices

The prices quoted include VAT. The currency is displayed in the web shop.

Subject to price increases, VAT adjustments, printing errors, pricing errors, clearance sales, inventory errors, etc. A ticket or item already shipped to you is not affected by any rise or fall in price that might occur after the order date, for example in the form of sales, promotions, discount codes or changes to the price.

##### Gift cards

A gift card cannot be redeemed for cash, nor can it be repurchased in whole or in part.

##### Adolescents

If you are under 18 years old, you must obtain the approval of a custodian in order to enter into an agreement through the Internet. This does not apply if you are 16 years old and have earned the money yourself.

Fotografiska reserve the right to contact custodians in the event that ticket purchasers are under 18 years of age. If you have not obtained the approval of a custodian, we cannot guarantee that your purchase will be accepted.

##### Right of withdrawal/Purchase on approval

The conditions vary here depending on what you have purchased.

**Tickets.**

A lost ticket cannot be redeemed. The provisions in the Swedish Distance Contracts Act on cancellation rights do not apply under the same act when purchasing event tickets. Consequently, you are bound by your purchase once payment has been made. When purchasing tickets, you can shape your experience through your selections and by adding extras (e.g. choice of eatery, addition of drinks package, etc.) for certain events. Your selections and any additions you make form part of your ticket purchase and are subject to the rules covering the purchase of tickets.

**Cancelled event/Repurchase.**

You are responsible for checking that the event has not been cancelled or rescheduled. If an event has been cancelled, you should contact Fotografiska, who is obliged to address any issues concerning the possible refund of tickets. In some cases, Fotografiska will refund tickets at the scheduled event. No postage or other fees paid in connection with the purchase of the ticket will be refunded following a cancelled event.

If you believe that your tickets should be repurchased, you must notify Fotografiska of your claim for a refund within two (2) months of the date of the event/day that the event would have been held. If this is not the case, you will not qualify for any refund.

##### Extraordinary circumstances

If the event has to be canceled due to extraordinary circumstances (fire, war, pandemics, terrorism, floods etc.), Fotografiska reserves the right to retain half the purchase price.

##### Customer support

If you need help, have encountered problems or need to get in touch for any other matter, please contact our customer support at <info@fotografiska.se>

If the matter involves an order already placed, it is important that you have the reference number of the order to hand (printed on your receipt).

##### Force Majeure

We consider ourselves exempt from fulfilling our obligations stated in these conditions in the event of any amendment to legislation, government actions, labour market conflicts, fires, flood, war, terrorism, hacking, major accidents or similar events.
`

const termsSv = `
### Kundvillkor
Dessa köpvillkor gäller mellan Fotografiska och dig (hädanefter kallad kunden) som köpare av biljetter och/eller relaterade varor.

##### Kundens skyldigheter vid beställning

Det är din plikt som kund att kontrollera att din kundvagn har rätt innehåll innan du slutför din beställning. Du måste också ange rätt information för att vi ska kunna garantera leverans av din beställning.

##### Personlig information

De personuppgifter du avslöjar behandlas med respekt och i enlighet med den allmänna dataskyddsförordningen (Europaparlamentets och rådets förordning (EU) nr 2016/679, GDPR). Läs mer om våra behandlingsförfaranden i vår personuppgiftspolicy.

##### Priser

De angivna priserna inkluderar moms. Valutan visas i webbshopen.

Med förbehåll för prishöjningar, momsjusteringar, tryckfel, prisfel, avyttringsförsäljning, lagerfel etc. En biljett eller artikel som redan skickats till dig påverkas inte av någon höjning eller minskning av priset som kan inträffa efter beställningsdatumet, till exempel i form av försäljning, kampanjer, rabattkoder eller prisändringar.

##### Presentkort

Ett presentkort kan inte lösas in mot kontanter, inte heller kan det återköpas helt eller delvis.

##### Ungdomar

Om du är under 18 år måste du få godkännande från en vårdnadshavare för att kunna ingå ett avtal via Internet. Detta gäller inte om du är 16 år och har tjänat in pengarna själv.

Fotografiska förbehåller sig rätten att kontakta vårdnadshavare om biljettköpare är under 18 år. Om du inte har fått godkännande från en vårdnadshavare kan vi inte garantera att ditt köp accepteras.

##### Ångerrätt/köp efter godkännande

Villkoren varierar här beroende på vad du har köpt.

**Biljetter.**

En förlorad biljett kan inte lösas in. Bestämmelserna i distansavtalslagen om avbokningsrätt gäller inte enligt samma lag vid köp av evenemangsbiljetter. Följaktligen är du bunden av ditt köp när betalningen har gjorts. När du köper biljetter kan du forma din upplevelse genom dina val och genom att lägga till extrafunktioner (t.ex. val av matställe, tillägg av dryckespaket, etc.) för vissa evenemang. Dina val och tillägg som du gör är en del av ditt biljettköp och omfattas av reglerna för köp av biljetter.

**Avbokat evenemang/återköp.**

Du ansvarar för att kontrollera att evenemanget inte har ställts in eller omplaneras. Om ett evenemang har ställts in bör du kontakta Fotografiska, som är skyldig att ta itu med eventuella frågor som rör eventuell återbetalning av biljetter. I vissa fall kommer Fotografiska att återbetala biljetter vid det schemalagda evenemanget. Inget porto eller andra avgifter som betalas i samband med köpet av biljetten återbetalas efter ett avbokat evenemang.

Om du anser att dina biljetter ska återköpas måste du meddela Fotografiska ditt krav på återbetalning inom två (2) månader efter datumet för evenemanget/dagen som evenemanget skulle ha hållits. Om så inte är fallet kommer du inte att få någon återbetalning.

##### Extraordinära omständigheter

Om evenemanget måste ställas in på grund av extraordinära omständigheter (brand, krig, pandemier, terrorism, översvämningar etc.) förbehåller Fotografiska sig rätten att behålla halva köpeskillingen.

##### Kundsupport

Om du behöver hjälp, har stött på problem eller behöver kontakta andra frågor, vänligen kontakta vår kundsupport på <info@fotografiska.se>

Om frågan handlar om en beställning som redan gjorts är det viktigt att du har beställningens referensnummer (tryckt på kvittot).

##### Force majeure

Vi anser oss befriade från att uppfylla våra skyldigheter enligt dessa villkor vid eventuella ändringar av lagstiftning, statliga åtgärder, arbetsmarknadskonflikter, bränder, översvämningar, krig, terrorism, hackning, större olyckor eller liknande händelser.
`

export class StoTerms extends React.Component {
  render() {
    return <>
      <div className="row center-xs middle-xs py2 px1">
        <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6">
          <div className="text-center">
            <Link to="/"><Logo/></Link>
          </div>
          <div className="content my2 text-left">

            <ReactMarkdown children={Locale.getShortLocale()=='en' ? termsEn : termsSv}></ReactMarkdown>


          </div>
        </div>
      </div>
    </>;
  }
}