import { get } from "../utils/globals";
import Auth from "../utils/auth";
import Locale from "../utils/locale";
import axios from "axios";

export function getApiHeaders(apiKey) {
  const context = get(["config", "timber_context"], false);
  let headers = {
    "x-api-key": apiKey,
    "accept-language": Locale.getShortLocale(),
  };
  if (Auth.getToken()) {
    headers["Authorization"] = "Bearer " + Auth.getToken();
  }
  return { context: context, headers };
}

export const Coupons = {
  async validateCoupon(code, apiKey) {
    let { context, headers } = getApiHeaders(apiKey);
    const params = {
      code,
    };

    return await axios
      .post(`${context.api_base}/coupons/validate`, params, {
        headers,
      })
      .then((response) => response.data.data);
  },
};

export const CMSBlocks = {
  async listCMSBlocks(apiKey, slugs = null) {
    let { context, headers } = getApiHeaders(apiKey);

    return await axios
      .get(`${context.api_base}/cms_blocks`, {
        headers,
        params: {
          slugs: slugs
        }
      })
      .then((response) => response)
      .catch((err) => console.log(err));
  },
};
