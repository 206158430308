function condsMatch(discount, {product}) {
  // analyze dynamic conds such as condition about left_usages
  return true;
}

function getMatchingDiscount(discounts, data) {
  return discounts ? discounts.find(discount => condsMatch(discount, data)) : null;
}

/**
 * Calculates a discounted price from a base price and discount.
 * Price may be negative
 *
 * @param matchingDiscount
 * @param productPrice
 * @returns {number}
 */
function applyDiscountRaw(matchingDiscount, productPrice) {
  switch (matchingDiscount.value_type) {
    case 'percent':
      // round to integer
      return Math.round(productPrice * (100 + parseFloat(matchingDiscount.value)) / 100);
    case 'fixed':
      // round to integer
      return Math.round(productPrice + parseFloat(matchingDiscount.value));
    case 'price':
      // round to integer
      return Math.round(matchingDiscount.value);
    default:
      throw `Unsupported valueType ${matchingDiscount.value_type}`;
  }
}

/**
 * Allow only zero or positive price
 * @param price
 * @returns {number|*}
 */
function applyPositivePrice(price) {
  if (price>=0) {
    return price;
  } else {
    return 0;
  }
}

function applyDiscount(productPrice, matchingDiscount) {
  if (matchingDiscount) {
    return applyPositivePrice(applyDiscountRaw(matchingDiscount, productPrice));
  } else {
    return productPrice;
  }
}

/**
 * Finds matching discount and applies it to productPrice
 *
 * `data` - includes the information needed to apply discount.
 *
 * `data = {
 *   timeslot,
 *
 * }`
 */
function applyDiscounts(product, productPrice, discounts, data) {
  const matchingDiscount = getMatchingDiscount(discounts, { product, ...data });
  return applyDiscount(productPrice, matchingDiscount, data);
}

function filterProductDiscounts(discounts, product) {
  return discounts ? discounts.filter(({product_ids}) => product_ids.includes(product.id)) : [];
}

/**
 * Calculates discounted price
 *
 * @param product
 * @param productPrice
 * @param timeslot
 * @returns {number|*}
 */
export function getDiscountedProductPrice(product, productPrice, timeslot) {
  let price = productPrice;
  if (timeslot) {
    const productDiscounts = filterProductDiscounts(timeslot.discounts, product);
    price = applyDiscounts(product, productPrice, productDiscounts, {timeslot});
  }
  return price;
}
