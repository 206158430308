import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import _ from "lodash";
import axios from "axios";
import validator from "validator";
import { confirmAlert } from "react-confirm-alert";

import { get } from "../utils/globals";
import { isZeroTotal } from "./util";
import { getProductGroupKey } from "./product";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { config } from "./config";

import PaymentsImg from "images/payments.png";
// import AmexImg from "images/amex/amex-logo.png";
// import Chase_Logo from "images/chase/Chase_Bonvoy_Logo.png";
import { Link } from "react-router-dom";
import Auth from "../utils/auth";
import { fbEvent } from "../utils/facebookPixel";
import { gtagConversion } from "../utils/gtagAds";
import { joinPath } from "../utils/path";
import Locale from "../utils/locale";
import { dataLayerConversion, dataLayerVariables, encodeSHA256 } from "../utils/dataLayer";
import CouponInput from "./couponInput";
import {
  // isAmexApp,
  isAmexCardsOnly,
  translations as amexTranslations,
} from "../utils/amex";
import AccessCodeInput from "./accessCodeInput";

// import ApplePay from './apple-pay';
import chase from "../utils/chase";
import { defaultCountry, otherCountries } from "../utils/countries";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: process.env.REACT_APP_TEST_EMAIL || "",
      phone: "",
      firstName: "",
      lastName: "",
      sources: [],
      doubleCheckEmail: false,
      requiresAction: false,
      clientSecret: null,
      boxMinHeight: null,
      termsAccepted: false,
      coupon: null,
      accessCode: null,
      postalCode: "",
      country: null,
      defaultCountry: defaultCountry,
      otherCountries: otherCountries,
      keepMeUpdated: false,
    };
  }

  componentDidMount() {
    if (Auth.isLoggedIn()) {
      const user = Auth.getUser();
      this.setState({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        country: user.country,
        postalCode: user.postalCode,
      });
    }
    this.updateBoxMinHeight();

    window.history.replaceState(
      null,
      null,
      joinPath(this.props.basePath, "checkout")
    );
    gtagConversion("pageview", {}); // used by snap pixel
  }

  updateBoxMinHeight() {
    const elem = document.getElementById("shopping-summary");
    const boxMinHeight = elem ? elem.clientHeight : null;
    this.setState({ boxMinHeight });
  }

  buildLineItems(cart, timeslots) {
    return _.uniqBy(cart, "id").map((uniqProduct) => {
      const {
        ticket_type: { timeslot_group_id } = { timeslot_group_id: null },
      } = uniqProduct;
      const groupKey = getProductGroupKey(uniqProduct);
      const timeslotId =
        groupKey in timeslots && timeslots[groupKey]
          ? timeslots[groupKey]
          : null;
      return {
        product_id: uniqProduct.id,
        quantity: cart.filter((p) => p.id == uniqProduct.id).length,
        timeslot_id: timeslotId,
      };
    });
  }

  addGiftcards() {
    const { sources } = this.state;
    const { giftcards } = this.props;
    giftcards.map((giftcard) => {
      sources.push(giftcard);
    });
    this.setState({ sources });
  }

  keepUpToDate() {
    const context = get(["config", "timber_context"], false);


    return new Promise((resolve, reject) => {
      let headers = {
        "x-api-key": this.props.apiKey,
        "accept-language": Locale.getShortLocale(),
      };
      let payload = {};
      if (Auth.isLoggedIn()) {
        const user = Auth.getUser();
        headers["Authorization"] = "Bearer " + Auth.getToken();
        payload = {
          sub_type: "email",
          email_category_id: process.env.REACT_APP_EMAIL_CATEGORY_ID,
          user_id: parseInt(user.id),
        };
      } else {
        payload = {
          sub_type: "email",
          email_category_id: process.env.REACT_APP_EMAIL_CATEGORY_ID,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          email: this.state.email,
        };
      }

      axios
        .post(
          `${context.api_base}/subscriptions`,
          {
            subscription: payload,
          },
          {
            headers: headers,
          }
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  payit() {
    const { sources, coupon, accessCode } = this.state;
    const { cart, timeslots, amount, giftcards } = this.props;
    const user = Auth.getUser();
    const context = get(["config", "timber_context"], false);
    let data = {};

    this.addGiftcards();

    data.cart = this.buildLineItems(cart, timeslots);
    data.amount = amount;
    data.email = this.state.email;
    data.phone = this.state.phone;
    data.first_name = this.state.firstName;
    data.last_name = this.state.lastName;
    data.postal_code = this.state.postalCode;
    data.country = this.state.country;
    data.user_id = user ? parseInt(user.id) : null;

    if (this.state.keepMeUpdated) {
      this.keepUpToDate();
    }

    return new Promise((resolve, reject) => {
      let headers = {
        "x-api-key": this.props.apiKey,
        "accept-language": Locale.getShortLocale(),
      };
      if (Auth.getToken()) {
        headers["Authorization"] = "Bearer " + Auth.getToken();
      }
      axios
        .post(
          `${context.api_base}/purchases`,
          {
            purchase: {
              send_receipt: config.send_receipt,
              user_id: data.user_id,
              store_id: 1,
              first_name: data.first_name,
              last_name: data.last_name,
              postal_code: data.postal_code,
              country: data.country,
              payment_info: {
                email: data.email,
                phone: data.phone,
                sources: sources,
              },
              line_items: data.cart,
              currency: config.currency.code,
              coupon_code: coupon?.code,
              access_code: accessCode,
            },
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          // console.log("Err");
          reject(err);
        });
    });
  }

  hideKeyboard() {
    //UGLY FIX FOR STRIPE INPUT FOCUS IN IFRAME..
    document.querySelector(".card_input iframe").focus();
    document.querySelector(".card_input iframe").blur();
  }

  handleAction() {
    const { clientSecret } = this.state;
    this.props.stripe
      .handleCardAction(clientSecret)
      .then((result) => {
        if (result.error) {
          this.props.onError(result.error.message);
        } else {
          this.resetRequiresAction();
          const { sources } = this.state;
          sources[
            sources.findIndex((el) => el.type == "stripe")
          ].payment_intent_id = result.paymentIntent.id;
          this.setState({ sources });

          this.completePayment();
        }
      })
      .catch((err) => {
        console.log("Handle card action error", err);
      });
  }

  completePayment() {
    const { translations } = this.props;

    this.payit()
      .then(async (response) => {
        await this.recordPurchase(response);
        this.props.onSuccess({
          response,
          deliveryMethod: this.getDeliveryMethod(),
        });
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  requiresAction(clientSecret) {
    this.setState({ clientSecret, requiresAction: true });
  }

  resetRequiresAction() {
    this.setState({ clientSecret: null, requiresAction: false });
  }

  isZeroTotal() {
    const { amount } = this.props;
    return isZeroTotal(amount);
  }

  handleSubmit(e) {
    const {
      firstName,
      lastName,
      email,
      phone,
      doubleCheckEmail,
      requiresAction,
      termsAccepted,
      postalCode,
      country,
    } = this.state;
    const { cart, amount, translations } = this.props;

    // const zeroTotal = isZeroTotal(amount);

    e.preventDefault();
    this.props.onSubmit();
    this.hideKeyboard();

    if (email == "") {
      this.props.onError(translations["E-mail is required"]);
    } else if (!validator.isEmail(email)) {
      this.props.onError(translations["E-mail is invalid"]);
    } else if (phone && !isValidPhoneNumber(phone)) {
      this.props.onError(translations["Mobile number is invalid"]);
    } else if (firstName.trim() == "") {
      this.props.onError(translations["First name is required"]);
    } else if (lastName.trim() == "") {
      this.props.onError(translations["Last name is required"]);
    } else if (!termsAccepted) {
      this.props.onError(translations["You must accept the terms to continue"]);
    } else if (amount > 0) {
      // if (requiresAction) {
      //   return this.handleAction();
      // }

      confirmAlert({
        title: translations["Confirm to submit"],
        message: `${translations["Is the email correct ?"]} ${email}`,
        onKeypressEscape: () => {
          this.props.onError();
        },
        onClickOutside: () => {
          this.props.onError();
        },
        buttons: [
          {
            label: translations["Confirm"],
            onClick: () => {
              this.props.stripe
                .createPaymentMethod("card", CardElement)
                .then((result) => {
                  if (result.error) {
                    this.props.onError(result.error.message);
                  } else {
                    const { sources } = this.state;
                    const foundIndex = sources.findIndex(
                      (el) => el.type == "stripe"
                    );
                    if (foundIndex === -1) {
                      sources.push({
                        type: "stripe",
                        amount: amount,
                        // "token": result.paymentMethod.id,
                        payment_method: result.paymentMethod.id,
                      });
                    } else {
                      // sources[foundIndex].token = result.paymentMethod.id;
                      sources[foundIndex].payment_method =
                        result.paymentMethod.id;
                    }
                    this.setState({ sources });

                    this.payit()
                      .then(async (response) => {
                        this.resetRequiresAction();
                        await this.recordPurchase(response);
                        this.props.onSuccess({
                          response,
                          deliveryMethod: this.getDeliveryMethod(),
                        });
                      })
                      .catch((error) => {
                        if (
                          error.response &&
                          error.response.data.requires_action
                        ) {
                          this.requiresAction(
                            error.response.data.data.client_secret
                          );
                          this.handleAction();
                        } else {
                          this.handleError(error);
                        }
                      });
                  }
                });
            },
          },
          {
            label: translations["Go back"],
            onClick: () => {
              this.props.onError();
            },
          },
        ],
      });
    } else {
      confirmAlert({
        title: translations["Confirm to submit"],
        message: `${translations["Is the email correct ?"]} ${email}`,
        onKeypressEscape: () => {
          this.props.onError();
        },
        onClickOutside: () => {
          this.props.onError();
        },
        buttons: [
          {
            label: translations["Confirm"],
            onClick: () => {
              this.payit()
                .then(async (response) => {
                  await this.recordPurchase(response);
                  this.props.onSuccess({
                    response,
                    deliveryMethod: this.getDeliveryMethod(),
                  });
                })
                .catch((error) => {
                  this.handleError(error);
                });
            },
          },
          {
            label: translations["Go back"],
            onClick: () => {
              this.props.onError();
            },
          },
        ],
      });
    }
  }

  handleError(error) {
    const { translations } = this.props;
    let message = translations["Something went wrong"];
    if (error.response) {
      // console.warn('2 -> ', error.response);
      switch (error.response.status) {
        case 403:
          message = translations["Not authorized to perform request"];
          break;
        case 422:
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.detail
          ) {
            message = error.response.data.errors.detail;
          } else {
            let msg = null;
            if (error.response.data.errors) {
              msg = Object.entries(error.response.data.errors)
                .map(([k, v]) => {
                  return v.join(" ");
                })
                .join(" ");
            }
            if (!msg) {
              msg = translations["Unprocessable entity"];
            }
            message = msg;
          }

          /*
          if (
            isAmexApp({ app: this.props.app, apiKey: this.props.apiKey }) &&
            message.includes("purchased only using AMEX")
          ) {
            message =
              "This pre-sale is restricted to Platinum Card and Centurion Members. Tickets for David LaChapelle's new exhibition 'make Believe' will be for sale to the public on July 21, 2022. Visit the Fotografiska New York website for more information.";
          }
          */

          /* chase
          if (
            chase.isChaseApp({
              app: this.props.app,
              apiKey: this.props.apiKey,
            }) &&
            message.includes("valid access code is required")
          ) {
            message = chase.translations.error;
          }
          */

          break;
        default:
          break;
      }
    } else if (error.request) {
      // console.warn('3 -> ', error.request);
    } else {
      // console.warn('4 -> ', error.message);
      message = error.message;
    }
    // console.warn('5 -> ', error);
    this.props.onError(message);
  }

  onCouponChanged(coupon) {
    this.setState({ coupon });
    this.props.onCouponChanged(coupon);
  }

  onAccessCodeChanged(accessCode) {
    this.setState({ accessCode });
    // this.props.onCouponChanged(accessCode);
  }

  render() {
    const context = get(["config", "timber_context"], false);

    const { cart, timeslots, amount, giftcards, translations, app } =
      this.props;
    const { boxMinHeight } = this.state;
    // const terms_url = getTermsUrl(context.language);

    // const zeroTotal = isZeroTotal(amount);

    const boxStyle = boxMinHeight != null ? { minHeight: boxMinHeight } : {};
    // const boxStyle = {};

    return (
      <form
        onSubmit={(e) => {
          this.handleSubmit(e);
        }}
        className="cart-box"
      >
        {this.isZeroTotal() ? (
          <p className="small mb3">
            <b className="text--h5 text--dark text--uppercase">
              {translations["Details"]}
            </b>
          </p>
        ) : (
          <p className="small mb3 flex">
            <b className="text--h5 text--dark text--uppercase">
              {translations["Card payment"]}
            </b>
            {
            /*
            isAmexCardsOnly({
              app: this.props.app,
              apiKey: this.props.apiKey,
            }) ? (
              <>
                <img
                  className="align-middle ml1 self-center"
                  height="50"
                  alt=""
                  src={AmexImg}
                  style={{ marginTop: "-8.5px" }}
                />
              </>
            ) : */
             /* chase.isChaseBoundlessPage({
                app: this.props.app,
                apiKey: this.props.apiKey,
              }) ? (
              <img
                className="chase__logo align-middle ml2 self-center"
                width="200"
                alt=""
                src={Chase_Logo}
              />
            ) : */
              <img
                className="align-middle ml1 self-center"
                width="100"
                alt=""
                src={PaymentsImg}
              />
            }
          </p>
        )}
        <div
          className="box box--grey box--no-shadow cart-box__min-height"
          style={boxStyle}
        >
          <div className="cart-box__names">
            <input
              type="text"
              name="first-name"
              autoComplete="billing given-name"
              className="col-xs-6 text-input block mb1 capitalize"
              placeholder={translations["First name"]}
              value={this.state.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
            />
            <input
              type="text"
              name="last-name"
              autoComplete="billing family-name"
              className="col-xs-6 text-input block mb1 capitalize"
              placeholder={translations["Last name"]}
              value={this.state.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
            />
          </div>

          <input
            type="email"
            name="email"
            className="text-input block fill-width mb1"
            placeholder={translations["E-mail address"]}
            autoComplete="billing email"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <div className="cart-box__names">
            <select
              type="text"
              name="country"
              className="col-xs-6 block mb1 select"
              value={this.state.country}
              autoComplete="billing country"
              onChange={(e) => this.setState({ country: e.target.value })}
            >
              <option>
                {translations["Country"]} ({translations["optional"]})
              </option>
              {defaultCountry && (
                <>
                  <option value={defaultCountry["alpha-2"]}>
                    {defaultCountry.name}
                  </option>
                  <option disabled>---</option>
                </>
              )}
              {otherCountries.map((country) => {
                return (
                  <option value={country["alpha-2"]}>{country.name}</option>
                );
              })}
            </select>
            <input
              type="text"
              name="postal-code"
              className="col-xs-6 text-input block mb1"
              autoComplete="billing postal-code"
              placeholder={
                translations[`${process.env.REACT_APP_BRANCH}.zip_code`] +
                " " +
                `(${translations["optional"]})`
              }
              value={this.state.postalCode}
              onChange={(e) => this.setState({ postalCode: e.target.value })}
            />
          </div>
          {/*<input type="tel" name="phone" className="text-input block fill-width mb1" placeholder={`${translations['Mobile number']} (${translations['optional']})`} value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value.replace(/^\s+|\s+$/g,'') })} />*/}
          <PhoneInput
            className="text-input block fill-width mb1"
            placeholder={`${translations["Mobile number"]} (${translations["optional"]})`}
            value={this.state.phone}
            defaultCountry={
              this.state.country || process.env.REACT_APP_DEFAULT_COUNTRY_CODE
            }
            onChange={(value) => this.setState({ phone: value })}
          />

          <CardElement
            className={`card_input py1 ${this.isZeroTotal() ? "hide" : ""}`}
            style={{
              base: {
                color: "#000",
                fontFamily: "minion-pro, serif",
                fontSmoothing: "antialiased",
                // fontSize: window.matchMedia('(max-width: 767px)').matches ? '15px' : '20px',
                fontSize: "20px",
                "::placeholder": {
                  color: "#5A5A5A",
                },
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
              },
            }}
          />
          <div id="card-errors" role="alert"></div>
        </div>

        {isAmexCardsOnly({ app: this.props.app, apiKey: this.props.apiKey }) ? (
          <>
            <AccessCodeInput
              translations={{ ...translations, ...amexTranslations }}
              apiKey={this.props.apiKey}
              onError={this.props.onError}
              onAccessCodeChanged={this.onAccessCodeChanged.bind(this)}
            />
          </>
        ) : chase.isAccessCodeRequired({
            app: this.props.app,
            apiKey: this.props.apiKey,
          }) ? (
          <>
            {/* keep general access code for future use */}
            <AccessCodeInput
              // translations={{ ...translations, ...chase.translations }}
              translations={{ ...translations }}
              apiKey={this.props.apiKey}
              onError={this.props.onError}
              onAccessCodeChanged={this.onAccessCodeChanged.bind(this)}
            />
          </>
        ) : (
          <>
            <CouponInput
              translations={translations}
              apiKey={this.props.apiKey}
              onError={this.props.onError}
              purchase={this.props.purchase}
              onCouponChanged={this.onCouponChanged.bind(this)}
            />
          </>
        )}

        <div className="my2 cart-box__action">
          <div className="my2 mb3">
            <input
              type="checkbox"
              name="checkbox"
              id="checkbox_keep_me_updated"
              className="checkbox"
              value="1"
              onChange={(e) =>
                this.setState({ keepMeUpdated: e.target.checked })
              }
            />
            <label
              htmlFor="checkbox_keep_me_updated"
              className="checkbox-label"
            >
              {translations["keep_me_updated"]}{" "}
            </label>
          </div>
          <div className="my2 mb3">
            <input
              type="checkbox"
              name="checkbox"
              id="tos_checkbox"
              className="checkbox"
              value="1"
              onChange={(e) =>
                this.setState({ termsAccepted: e.target.checked })
              }
            />
            <label htmlFor="tos_checkbox" className="checkbox-label">
              {translations["I accept the"]}{" "}
              <Link target="_blank" className="link--underlined" to="/terms">
                {translations["terms and conditions"]}
              </Link>
            </label>
          </div>
          <input
            type="submit"
            className="btn btn--primary btn--cta-next"
            value={`${translations["Confirm"]}`}
          />
        </div>
        <div className="my2">
          <p className="small text--b1">
            {
              translations[
                "The ticket will be delivered to your e-mail."
              ]
            }
          </p>
        </div>
      </form>
    );
  }

  getDeliveryMethod() {
    const { phone } = this.state;
    return phone != null && isValidPhoneNumber(phone) ? "sms" : "email";
  }

  async recordPurchase(response) {
    fbEvent("Purchase", this.props.fbPurchase);
    gtagConversion("conversion", {
      send_to: `${process.env.REACT_APP_GTAG_ID}/DgNWCLTTvNUCELenovIC`,
      transaction_id: (response && response.data && response.data.id) || "",
    });
    const event = this.props.dataLayerEvent;
    const purchaseId = (response && response.data && response.data.id) || "";
    event.ecommerce.purchase.actionField.id = purchaseId;


    // Used by Snap Pixel
    const email = this.state.email?.trim()?.toLowerCase() || null;
    const phone = this.state.phone?.trim()?.toLowerCase() || null;
    dataLayerVariables({
      user_email: email,
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      geo_country: this.state.country,
      geo_postal_code: this.state.postalCode,
      user_phone_number: phone,
      user_hashed_email: await encodeSHA256(email),
      user_hashed_phone_number: await encodeSHA256(phone),
      uuid_c1: Auth.isLoggedIn() ? Auth.getUser().id : null,
      payment_info_available: this.isZeroTotal() ? 0 : 1,
      transaction_id: purchaseId === "" ? null : purchaseId,
    });
    gtagConversion("purchase", {}); // used by snap pixel
    dataLayerConversion(event);
  }
}

export default injectStripe(Form);
