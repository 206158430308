import React, {Component} from "react";
import Auth from "../utils/auth";
import {config} from "./config";
import {string} from "../utils/translate";

export class UserMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      user: null,
      basePath: null,
    }
  }

  componentDidMount() {
    let loggedIn = Auth.isLoggedIn();
    this.setState({
      loggedIn: loggedIn,
      user: loggedIn ? Auth.getUser() : null,
    })
  }

  logout() {
    Auth.logout();
    this.setState({
      loggedIn: false,
      user: null,
    })
  }

  login() {
    window.location = config.urls.membersApp + `signup/login?tickets=${this.props.absPath}`;
    // window.location = config.urls.singleSignOn + `?return_to=${encodeURI(this.props.absPath)}`;
  }

  becomeMember() {
    window.location = config.urls.membersApp + `signup?tickets=${this.props.absPath}`;
    // window.location = config.urls.becomeMember;
  }

  render() {
    const {loggedIn, user} = this.state;
    return <>
      {/*<i className="icon icon-profile icon--sm mr1"></i>*/}
      {loggedIn ? <>
          {user && <>
            {/*<span className="">
              Welcome, {user.firstName} {user.lastName}
            </span>*/}
            {user.membership && user.membership.membershipType ?
              <>
                <a href={config.urls.membersApp + `profile?tickets=${encodeURI(this.props.absPath)}`} className="btn btn--pills mob-small small">{string('My account')}</a>
                <a href={config.urls.membersApp + `?tickets=${encodeURI(this.props.absPath)}`} className="btn btn--pills mob-small small">{string('My membership')}</a>
              </>
              :
              <>
                <a href={config.urls.membersApp + `profile?tickets=${encodeURI(this.props.absPath)}`} className="btn btn--pills mob-small small">{string('My account')}</a>
                <a href={config.urls.membersApp + `?tickets=${encodeURI(this.props.absPath)}`} className="btn btn--pills small mob-small btn--next">{string('Become a member')}</a>
              </>

            }
            {/*<button className="btn btn--pills small" onClick={this.logout.bind(this)}>Logout</button>*/}
          </>}
          {/*{user.membership && user.membership.membershipType && <div className="">
            <span className="mr1">{user.membership.membershipType.name}</span>
            <span className=""><a href={config.urls.membersApp} className="btn btn--pills small">Edit</a></span>
          </div>}*/}
        </>
        : <>
          <button className="btn btn--pills small mob-small" onClick={this.login.bind(this)}>{string('Sign in')}</button>
          {' '}
          <button className="btn btn--pills small mob-small btn--next" onClick={this.becomeMember.bind(this)}>{string('Become a member')}</button>
        </>}
    </>;
  }

}