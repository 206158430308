import {Component} from "react";
import {Coupons} from "./api";
import Coupon from "./api/coupon";

export default class CouponInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addCoupon: false,
      coupon: null,
      couponText: ""
    };
  }

  async verify() {
    try {
      this.props.onError(null);
      const response = await Coupons.validateCoupon(this.state.couponText, this.props.apiKey);
      Coupon.canApply(response?.coupon, this.props.purchase);

      this.setState({
        coupon: response?.coupon,
        addCoupon: false,
        couponText: "",
      });
      this.props.onCouponChanged(response?.coupon)
    } catch (e) {
      const message = e.response?.data?.data?.error || e.message || "Coupon validation failed";
      this.props.onError(message);
      this.setState({
        coupon: null,
        addCoupon: false,
        couponText: "",
      });
      this.props.onCouponChanged(null)
    }
  }

  render() {
    const {addCoupon, coupon} = this.state;
    const {translations} = this.props;
    return <div>
      {addCoupon && !coupon &&
        this.renderForm(translations)
      }
      {!addCoupon && !coupon &&
        this.renderButton(translations)
      }
      {coupon &&
        this.renderCoupon(translations)
      }
    </div>;
  }

  remove() {
    this.setState({
      coupon: null,
    });
    this.props.onCouponChanged(null)
  }

  renderButton(translations) {
    return <span className="btn"
                 onClick={() => this.setState({addCoupon: true})}>{translations['Add coupon']}</span>;
  }

  renderForm(translations) {
    return <div className="coupon-input mt4 mb4">
      <p className="small mb1 text--h6 text--dark text--uppercase">{translations['Coupon']}</p>
      <input type="text" name="coupon" className="text-input block fill-width mb1"
             placeholder={translations['Enter code']} value={this.state.couponText || ""}
             onChange={(e) => this.setState({couponText: e.target.value})}
             onKeyDown={(e) => {
               if (e.key === 'Enter') {
                 e.preventDefault();
                 this.verify()
               }
             }}/>

      <button className={"btn btn--cta" + (this.state.couponText == "" ? " disabled" : "")} type="button"
              onClick={() => this.verify()}
              disabled={this.state.couponText == ""}>{translations['Apply']}</button>
      <button className="btn" type="button"
              onClick={() => this.setState({addCoupon: false})}>{translations['Cancel']}</button>
    </div>;
  }

  renderCoupon(translations) {
    const {coupon} = this.state;
    return <div className="coupon-input">
      <p className="small mb1">
        <span className="mr1">{translations['Coupon']}</span>
        <span className="code mr3">{coupon.code}</span>
        <button type="button" className="btn" onClick={() => this.remove()}>{translations['Remove']}</button>
      </p>
      {/*<div className="mt2 mb2">
        Coupon: <span className="code">{coupon.code}</span>
      </div>*/}
      {/*<div>
        <button type="button" className="btn" onClick={() => this.remove()}>Remove</button>
      </div>*/}
    </div>;
  }
}