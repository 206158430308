import {config} from "./config";

export function isZeroTotal(amount) {
  return amount <= 0.0000001;
}

export function getSiteUrl() {
  return typeof(window.wp_globals)!='undefined' && window.wp_globals.config && window.wp_globals.config.timber_context && window.wp_globals.config.timber_context.site && window.wp_globals.config.timber_context.site.site_url || '/';
}

export function getTermsUrl(locale) {
  return getSiteUrl() + "/" + (locale ? locale + "/" : "") + "customer-terms-and-conditions/";
}

export function formatPercent(percent) {
  const floatPercent = parseFloat(percent);
  let percentStr;
  if (Math.abs(Math.floor(floatPercent) - floatPercent)<0.01) {
    percentStr = "" + Math.floor(floatPercent);
  } else {
    percentStr = "" + Math.round(floatPercent * 100)/100.0;
  }
  return (floatPercent > 0 ? "+" + percentStr : percentStr) + "%";
}

export function roundPrice(price) {
  return Math.round((price + Number.EPSILON) * 100) / 100;
}

export function roundToPlaces(price, places) {
  const k = Math.pow(10, places);
  return Math.round((price + Number.EPSILON) * k) / k;
}


export function formatPrice(price) {
  const v = Math.round((price + Number.EPSILON) * 100) / 100;
  const intPrice = Math.round(price + Number.EPSILON);
  if (Math.abs(v - intPrice) <= Number.EPSILON) {
    return intPrice;
  } else {
    return v.toFixed(2);
  }
}

export function formatPriceCurrency(v) {
  let amount = v;
  if (typeof (v) == 'number') {
    amount = formatPrice(v);
  }
  return config.currency.format.replace("{{amount}}", amount);
}

export function isRangedPrice(...prices) {
  if (prices.length == 0) {
    return null;
  }
  const {min, max} = {min: Math.min(...prices), max: Math.max(...prices)};
  if (Math.abs(min - max) <= Number.EPSILON) {
    return false;
  }
  return true;
}

export function formatPriceInCurrencyRange(...prices) {
  if (prices.length == 0) {
    return null;
  }
  const {min, max} = {min: Math.min(...prices), max: Math.max(...prices)};
  if (Math.abs(min - max) <= Number.EPSILON) {
    return formatPriceCurrency(max);
  } else {
    return formatPriceCurrency(`${formatPrice(min)}\u00a0–\u00a0${formatPrice(max)}`);
  }
}
