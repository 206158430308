export function isAmexInfo({app, apiKey}) {
  return false;
  // return app == "American_Express_Platinum_David_LaChapelle";
}


export function isAmexApp({app, apiKey}) {
  return false;
  // return (process.env.REACT_APP_AMEX_API_KEYS || []).includes(apiKey);
}

export function isAmexCardsOnly({app, apiKey}) {
  return false;
  // return (process.env.REACT_APP_AMEX_CARD_APPS || []).includes(apiKey);
}

export function isAmexSponsorship(props) {
  return false;
  // return isAmexApp(props) || (props.app == "default" && process.env.REACT_APP_BRANCH == "new_york");
}

export function isAmexBanner(props) {
  return false;
  // return isAmexApp(props) || (props.app == "default" && process.env.REACT_APP_BRANCH == "new_york");
}


export function getAmexPath() {
  return "/American_Express_Platinum_David_LaChapelle";
}

export const translations = {
  "Enter your access code": "Enter your American Express® special access code",
  "access_code_instructions": "American Express Platinum Card® and Centurion® Members, please enter the 10-digit toll-free Customer Service phone number (without spaces, dashes, or the initial \"1\") from the back of your Card as the Access Code to complete your ticket purchase.",
}