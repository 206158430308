import React from 'react';
import Block from "./block";


const TopBar = (props) => {
  const content = <Block {...props} slugs="topBar"/>;
  return <>
    {content!="" && <div className="top-bar">{content}</div>}
  </>;
}

export default TopBar;
