import axios from 'axios';
import numeral from 'numeral';
import { Component } from 'react';
import Modal from 'react-modal';
import { Elements, StripeProvider } from 'react-stripe-elements';

import Form from './form';

import { get } from '../utils/globals';
import { config } from "./config";
import { formatTimeslotDate, formatTimeslotTimeRange } from "./timeslot";
import { formatPriceCurrency, getSiteUrl, isZeroTotal } from "./util";

// import {ReactComponent as LogoSmall} from 'images/logo_small.svg';
import { ReactComponent as LoadingImg } from 'images/loader.svg';
import { dataLayerVariables, dataLayerConversion, dataLayerRemoveVariable } from '../utils/dataLayer';
import { resetDataLayerProp, gtagConversion } from "../utils/gtagAds";
import { joinPath } from "../utils/path";
import { Purchase } from "./api/purchase";
import { Logo } from "./logo";

export default class CheckoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "payment",
      error: false,
      show: true,
      loading: false,
      giftcards: [],
      giftcard_number: "",
      giftcard_pin: "",
      giftcard_amount: "",
      deliveryMethod: "email",
      purchase: new Purchase({
        cart: props.cart,
        timeslots: props.timeslots,
        productGroups: props.productGroups,
        coupon: null,
      }),
    };
  }

  componentDidMount() {
    const { purchase } = this.state;

    dataLayerVariables({
      item_ids: purchase.getProductIds().map((i) => i.toString()),
      number_items: purchase.getTotalQuantity(),
      price: purchase.total,
      payment_info_available: 0,
      transaction_id: null,
    });
    gtagConversion("pageview", {}); // used by snap pixel

    gtagConversion("add_cart", {}); // used by snap pixel
    gtagConversion("start_checkout", {}); // used by snap pixel
  }

  shoppingSummary() {
    const { translations } = this.props;
    const { purchase } = this.state;
    return (
      <div>
        {purchase.groups.map(({ index, timeslot, items }) => {
          return (
            <div className="summary-group" key={index}>
              {items.map(({ product, baseTotal, quantity }) => {
                return (
                  <div
                    className="flex items-center justify-between"
                    key={product.id}
                  >
                    <p className="small mb1">
                      {product.name} x{quantity}
                    </p>
                    <p className="small mb1 self-start">
                      {(baseTotal != 0 || !product.hide_price) &&
                        formatPriceCurrency(baseTotal)}
                    </p>
                  </div>
                );
              })}
              {timeslot && (
                <div className="flex items-center justify-between summary-timeslot">
                  <p className="small mb1">
                    {config.timeslot.format == "arrival"
                      ? translations["Arrival"]
                      : translations["Above is valid"]}
                  </p>
                  <p className="small mb1">
                    <span className="mr1">
                      {formatTimeslotDate(timeslot.timeslot_date)}
                    </span>
                    <span>{formatTimeslotTimeRange(timeslot)}</span>
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  shoppingSummaryTotals() {
    const { total, translations } = this.props;
    const { giftcards, coupon, purchase } = this.state;
    return (
      <div>
        {giftcards.map((giftcard, key) => {
          return (
            <div className="flex items-center justify-between mt1" key={key}>
              <p className="small m0">
                <b>{`${translations["Gift card"]} ****${giftcard.code
                  .toString()
                  .slice(-4)}`}</b>
              </p>
              <p className="small m0">
                <b>{formatPriceCurrency(-giftcard.amount)}</b>
              </p>
            </div>
          );
        })}
        {purchase.couponDiscount > 0 && (
          <>
            <div className="flex items-center justify-between mt1">
              <p className="small m0">
                <b>{translations["Subtotal"]}</b>
              </p>
              <p className="small m0">
                <b>
                  {formatPriceCurrency(
                    purchase.baseTotal - this.giftCardTotals()
                  )}
                </b>
              </p>
            </div>
            <div className="flex items-center justify-between mt1">
              <p className="small m0">
                <b>{translations["Coupon"]} </b>
                <br />
                <small>
                  {purchase.coupon.discount_type == "percent" && (
                    <>
                      <span>
                        {purchase.coupon.discount}% {translations["off"]}
                      </span>
                      {purchase.coupon.product_ids &&
                        purchase.coupon.product_ids.length > 0 && (
                          <span> {translations["selected items"]}</span>
                        )}
                    </>
                  )}
                  {purchase.coupon.discount_type == "purchase_amount" && (
                    <span>
                      {formatPriceCurrency(-purchase.coupon.discount)}
                    </span>
                  )}
                  {purchase.coupon.discount_type == "unit_amount" && (
                    <>
                      <span>
                        {formatPriceCurrency(-purchase.coupon.discount)}{" "}
                        {translations["per item"]}
                      </span>
                      {purchase.coupon.product_ids &&
                        purchase.coupon.product_ids.length > 0 && (
                          <span>, {translations["selected items"]}</span>
                        )}
                    </>
                  )}
                </small>
              </p>
              <p className="small self-start m0">
                <b>-{formatPriceCurrency(purchase.couponDiscount)}</b>
              </p>
            </div>
          </>
        )}
        <div className="flex items-center justify-between mt1">
          <p className="small m0">
            <b>{translations["Total"]}</b>
          </p>
          <p className="small m0">
            <b>{formatPriceCurrency(purchase.total - this.giftCardTotals())}</b>
          </p>
        </div>
      </div>
    );
  }

  giftCardVerify() {
    const { translations } = this.props;
    const { giftcard_number, giftcard_pin } = this.state;
    const config = get(["config", "timber_context"], false);
    this.setState({ loading: true });

    if (giftcard_number == "") {
      this.error(translations["Gift card number is required"]);
    } else if (giftcard_pin == "") {
      this.error(translations["Gift card pin is required"]);
    } else {
      axios
        .get(`${config.api_base}/retain24`, {
          params: {
            code: giftcard_number,
            pin: giftcard_pin,
          },
          headers: {
            "x-api-key": this.props.apiKey,
          },
        })
        .then((res) => {
          if (res.data == "no_gift_card_found") {
            this.error("Invalid cardnumber or pin");
            this.setState({ giftcard_totalval: 0, giftcard_valid: false });
          } else {
            this.error("");
            this.setState({
              giftcard_totalval: res.data.qty,
              giftcard_valid: true,
              loading: false,
            });
          }
        })
        .catch((err) => {
          this.error("Invalid cardnumber or pin");
          this.setState({ giftcard_totalval: 0, giftcard_valid: false });
        });
    }
  }

  giftCardReserve() {
    const {
      purchase,
      giftcard_number,
      giftcard_pin,
      giftcard_totalval,
      giftcard_amount,
      giftcards,
    } = this.state;
    const { total, translations } = this.props;
    const config = get(["config", "timber_context"], false);
    this.setState({ loading: true });

    if (giftcard_amount <= 0 || giftcard_amount == "") {
      this.error(translations["Amount is required"]);
    } else if (giftcard_amount > purchase.total) {
      this.error(translations["Amount can't be greater than total value"]);
    } else {
      this.error("");

      setTimeout(() => {
        giftcards.push({
          type: "gc",
          code: giftcard_number,
          pin: giftcard_pin,
          amount: parseInt(giftcard_amount),
        });
        this.setState({
          giftcards,
          giftcard_number: "",
          giftcard_pin: "",
          giftcard_totalval: "",
          giftcard_amount: "",
          giftcard_valid: false,
          giftcard_error: "",
          loading: false,
          error: false,
        });
      }, 1000);
    }
  }

  giftCardTotals() {
    const { giftcards } = this.state;
    let total = 0;

    giftcards.map((giftcard) => {
      total += giftcard.amount;
    });
    return total;
  }

  success({ response, deliveryMethod }) {
    const { cart, translations } = this.props;
    this.setState({ deliveryMethod: deliveryMethod });
    // const unique = _.uniqBy(cart, 'id');
    /*const arr = _.orderBy(unique.map(product => {
        const amount = _.filter(cart, {id: product.id}).length;
        return {
            ...product,
            amount
        }
      })
    )*/

    this.setState({ view: "confirmed", error: false, loading: false });

    window.history.replaceState(
      null,
      null,
      joinPath(this.props.basePath, "thank-you")
    );
    gtagConversion("pageview", {}); // used by snap pixel

    this.props.onSuccess({ response, deliveryMethod });
  }

  error(resp) {
    this.setState({ error: resp, loading: false });
  }

  close() {
    window.history.replaceState(null, null, this.props.basePath);
    dataLayerRemoveVariable("item_ids");
    gtagConversion("pageview", {}); // used by snap pixel
    this.props.hide();
  }

  onCouponChanged(coupon) {
    this.setState({
      purchase: new Purchase({
        cart: this.props.cart,
        timeslots: this.props.timeslots,
        productGroups: this.props.productGroups,
        coupon: coupon,
      }),
    });
  }

  render() {
    const { show, cart, timeslots, total, translations, app } = this.props;
    const { view, error, loading, summaryBoxHeight, deliveryMethod } =
      this.state;
    const {
      purchase,
      giftcard_number,
      giftcard_pin,
      giftcard_totalval,
      giftcard_amount,
      giftcards,
      giftcard_error,
      giftcard_valid,
    } = this.state;
    const context = get(["config", "timber_context"], false);
    const site_url = getSiteUrl();

    return (
      <Modal
        avoidKeyboard={false}
        className={{
          base: "",
          afterOpen: "",
          beforeClose: "",
        }}
        isOpen={this.state.show}
        onRequestClose={() => this.props.hide()} //this.setState({show: false})}
        contentLabel="Modal"
        ariaHideApp={false}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "0px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
          },
        }}
      >
        {error ? (
          <div
            className="notice error animated fadeInDownBig"
            onClick={() => this.setState({ error: false })}
          >
            <a className="notice__close" href="#">
              <i className="icon icon-close icon--white icon--sm"></i>
            </a>
            <p className="small m0">{error}</p>
          </div>
        ) : null}
        <div className="modal__content">
          <div className="modal__content__header">
            <div className="container">
              <div className="flex justify-between items-center">
                {/*<LogoSmall/>*/}
                <Logo />
                {view != "confirmed" ? (
                  <div
                    className="modal-btn-close"
                    onClick={() => this.props.hide()}
                  >
                    <i className="icon icon-close icon--sm"></i>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="py3">
            <div className="container container--slim">
              <div
                className={`checkout-container ${loading ? "loading" : null}`}
              >
                <div className="checkout-loader">
                  <LoadingImg />
                </div>
                {view == "payment" ? (
                  <div>
                    {
                      // <h3 className="py2">{translations['Buy ticket']}</h3>
                    }
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <p className="small text--h5 text--uppercase text--dark mb4 ">
                          <b>{translations["Summary"]}</b>
                        </p>
                        <div
                          className="box box--grey box--no-shadow"
                          id="shopping-summary"
                        >
                          <p className="small mb1 text--h6 text--dark text--uppercase">
                            <b>{translations["Ticket"]}</b>
                          </p>
                          {this.shoppingSummary()}
                          <hr />
                          {this.shoppingSummaryTotals()}
                        </div>
                        {config.paymentOptions.gc.enabled &&
                        !isZeroTotal(purchase.total) &&
                        this.giftCardTotals() < purchase.total ? (
                          <div>
                            <p className="small my2">
                              <b>{translations["Gift card"]}</b>
                            </p>
                            <div
                              className="box box--grey box--no-shadow"
                              id="shopping-summary"
                            >
                              <div className="row">
                                <div className="col-xs-12">
                                  <input
                                    type="number"
                                    className="text-input fill-width"
                                    value={giftcard_number}
                                    onChange={(e) =>
                                      this.setState({
                                        giftcard_number: e.target.value,
                                      })
                                    }
                                    placeholder={
                                      translations["Gift card number"]
                                    }
                                  />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                  <input
                                    type="number"
                                    className="text-input fill-width"
                                    value={giftcard_pin}
                                    onChange={(e) =>
                                      this.setState({
                                        giftcard_pin: e.target.value,
                                      })
                                    }
                                    placeholder={translations["Pin"]}
                                  />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                  <button
                                    className="btn btn--primary btn--small fill-width"
                                    onClick={() => this.giftCardVerify()}
                                  >
                                    {translations["Verify"]}
                                  </button>
                                </div>
                                {!giftcard_valid ? (
                                  <p className="small m0">{giftcard_error}</p>
                                ) : null}
                              </div>
                              {giftcard_valid ? (
                                giftcard_totalval > 0 ? (
                                  <div className="row">
                                    <div className="col-xs-12">
                                      <p className="small m0">{`${
                                        translations["Balance"]
                                      }: ${numeral(
                                        giftcard_totalval / 100
                                      ).format("0,0")} ${
                                        context.currency_symbol
                                      }`}</p>
                                    </div>
                                    <div className="col-xs-12 col-sm-8">
                                      <input
                                        type="number"
                                        className="text-input fill-width"
                                        value={giftcard_amount}
                                        onChange={(e) =>
                                          this.setState({
                                            giftcard_amount: e.target.value,
                                          })
                                        }
                                        placeholder={`${translations["Amount to use"]} (max ${purchase.total} ${context.currency_symbol})`}
                                        min="0"
                                        max={purchase.total}
                                      />
                                    </div>
                                    <div className="col-xs-12 col-sm-4">
                                      <button
                                        className="btn btn--primary btn--small fill-width"
                                        onClick={() => this.giftCardReserve()}
                                      >
                                        {translations["Use"]}
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <p className="small m0">{`${translations["Balance"]}: 0`}</p>
                                )
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-xs-12 col-sm-6 m-mt4">
                        <StripeProvider apiKey={context.stripe_pk}>
                          <Elements>
                            <Form
                              fbPurchase={this.props.fbPurchase}
                              purchase={purchase}
                              dataLayerEvent={this.getDataLayerEvent()}
                              apiKey={this.props.apiKey}
                              basePath={this.props.basePath}
                              app={this.props.app}
                              cart={cart}
                              totalToPay={purchase.total}
                              baseTotal={purchase.baseTotal}
                              timeslots={timeslots}
                              translations={translations}
                              amount={purchase.total - this.giftCardTotals()}
                              onSuccess={(opts) => this.success(opts)}
                              onSubmit={() =>
                                this.setState({ loading: true, error: false })
                              }
                              onError={(resp) => this.error(resp)}
                              giftcards={giftcards}
                              onCouponChanged={this.onCouponChanged.bind(this)}
                            />
                          </Elements>
                        </StripeProvider>
                      </div>
                    </div>
                  </div>
                ) : null}

                {view == "confirmed" ? (
                  <div className="">
                    <h3 className="text--dark text--h6 text--uppercase">
                      {translations["Thank you for your purchase"]}
                    </h3>
                    <p className="mt3">
                      {deliveryMethod == "email" &&
                        translations[
                          "Your ticket has now been sent as an e-mail."
                        ]}
                      {deliveryMethod == "sms" &&
                        translations[
                          "Your ticket has now been sent as an e-mail and SMS."
                        ]}
                    </p>
                    {false && (
                      <p className="mt1">
                        {
                          translations[
                            "Your ticket has now beeen sent as an email. Don’t forget to download our app to get the most out of your visit."
                          ]
                        }
                      </p>
                    )}
                    <p className="mt2">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.close();
                        }}
                        className="btn btn--cta"
                      >
                        {translations["Back to tickets"]}
                      </a>
                    </p>
                    {context.app_link && false ? (
                      <a
                        className="text-center"
                        target="_blank"
                        href={context.app_link}
                      >
                        <img
                          className="fit img"
                          height="50"
                          src={`${context.imageLink}appstore.png`}
                          alt=""
                        />
                      </a>
                    ) : null}
                    {context.app_link_android && false ? (
                      <a
                        className="text-center"
                        target="_blank"
                        href={context.app_link_android}
                      >
                        <img
                          className="fit img ml1"
                          height="50"
                          src={`${context.imageLink}android.png`}
                          alt=""
                        />
                      </a>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  getDataLayerEvent() {
    const { purchase } = this.state;

    const products = purchase.groups.reduce((acc, { items }) => {
      const groupProducts = items.map(({ product, quantity, price, total }) => {
        return {
          name: product.name, // Name or ID is required.
          id: product.id,
          price: price,
          brand: `Fotografiska ${process.env.REACT_APP_BRANCH.toUpperCase()}`,
          category:
            (product.ticket_type && product.ticket_type.name) || "Ticket",
          variant: "",
          quantity: quantity,
          coupon: purchase.coupon?.code || "",
        };
      });
      return [...acc, ...groupProducts];
    }, []);

    return {
      event: "ticketPurchased",
      ecommerce: {
        currencyCode: config.currency.code,
        purchase: {
          actionField: {
            id: `T${Date.now()}`, // Transaction ID. Required for purchases and refunds.
            affiliation: "fotografiska.com",
            revenue: purchase.total, // Total transaction value (incl. tax and shipping)
            tax: "0",
            shipping: "0",
            coupon: purchase.coupon?.code || "",
          },
          products: products,
        },
      },
    };
  }
}
