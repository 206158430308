import en_strings from '../locale/en-US.json';
import sv_strings from '../locale/sv-SE.json';
import Locale from "./locale";

export function  strings() {
  const strings = {
    'sv-SE': sv_strings,
    'en-US': en_strings,
  }
  const language = Locale.getLocale();
  if (language in strings) {
    return strings[language];
  }

  return en_strings;
}

export function string(string) {
  const localeStrings = strings();
  return string in localeStrings ? localeStrings[string] : string;
}
