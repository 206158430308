import {getDiscountedProductPrice} from "./discounts";
import {config} from "./config";

export const getProductGroupKey = (product) => {
  const {
    ticket_type: {timeslot_group_id} = {timeslot_group_id: null},
    timeslot_valid_since = null,
    timeslot_valid_until = null
  } = product;
  return [timeslot_group_id, timeslot_valid_since, timeslot_valid_until].join('|')
}

export const parseProductGroupKey = (key) => {
  const parts = key.split("|");
  return {
    timeslot_group_id: parts[0] != '' ? parseInt(parts[0]) : null,
    timeslot_valid_since: (parts[1] != '' ? parts[1] : null),
    timeslot_valid_until: (parts[2] != '' ? parts[2] : null)
  }
}

export class ProductHelper {

  static isMemberPriceDisabled(product, date) {
    const {member_price_exclude_dates} = product;
    if (date) {
      return member_price_exclude_dates && member_price_exclude_dates.includes(date);
    } else {
      return false;
    }
  }

  static getAvailableOn(product, date) {
    if (this.isMemberPriceDisabled(product, date)) {
      // user has already purchased this ticket on the same date - use standard (non-member) price and availability
      const {non_member_available_for_purchase} = product;
      return non_member_available_for_purchase;
    } else {
      const {available_for_purchase} = product;
      return available_for_purchase;
    }
  }

  static calcProductPrice(product, timeslot) {
    let productPrice = product.price;
    let applyDiscount = product.price_type == 'default'; // we apply discount only for default (non-members) pricing
    // console.log(product.name, ' -> ', product.price_type, applyDiscount);


    if (timeslot && this.isMemberPriceDisabled(product, timeslot.timeslot_date)) {
      // user has already purchased a ticket on this date - use standard price
      productPrice = product.non_member_price;
      applyDiscount = true;
    }

    return applyDiscount ? getDiscountedProductPrice(product, productPrice, timeslot) : productPrice;
  }


  static getMaxAvailableOn(product, date) {
    const available_for_purchase = this.getAvailableOn(product, date);
    return available_for_purchase == null ? config.limits.maxAvail : available_for_purchase
  }
}
