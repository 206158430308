import React from "react";
import {branchedComponent} from "../utils/branch";
import {string} from "../utils/translate";
import {getAmexPath, isAmexApp} from "../utils/amex";

// function NavAmexNYC(props) {
//   return <div className="text-left tickets-nav">
//     <a className={"btn btn--pills small nav " + (props.app == "default" ? 'active' : '')} href={props.basePath}>American Express<sup>®</sup> Platinum Pre-Sale Admission</a>
//   </div>;
// }


function NavNormalNYC(props) {
  return <div className="text-left tickets-nav">
      <div className="">
        <a className={"btn btn--pills small nav " + (props.app == "default" ? 'active' : '')} href="/">Admission</a>
        <a className={"btn btn--pills small nav " + (props.app == "events" ? 'active' : '')}
           href="/events">Events</a>
      </div>
      {/* {(props.app == "default" || isAmexApp(props)) &&
        <>
          <div className="">
            <a
              className={"btn btn--pills small nav btn--next " + (props.app == "David-LaChapelle" ? 'active' : '')}
              href={props.app == "David-LaChapelle" ? null : '/David-LaChapelle'}>David LaChapelle 'make BELIEVE'</a>
          </div>
        </>
      } */}
    </div>;
}

function NavNYC(props) {
  // return isAmex(props.app) ? NavAmexNYC(props) : NavNormalNYC(props);
  return NavNormalNYC(props);
}

function NavSTO(props) {
  return <div className="text-left tickets-nav">
    <a className={"btn btn--pills small nav " + (props.app == "default-tickets" ? 'active' : '')}
       href="https://tickets-sto.fotografiska.com/">{string('Admission')}</a>
    <a className={"btn btn--pills small nav " + (props.app == "default-events" ? 'active' : '')}
       href="https://live-sto.fotografiska.com/">{string('Events')}</a>
  </div>;
}

export const Nav = branchedComponent({stockholm: NavSTO, new_york: NavNYC});

