import React from "react";
import {branchedComponent} from "../utils/branch";
import {string} from "../utils/translate";
// import {getAmexPath, isAmexApp} from "../utils/amex";
// import chase from "utils/chase";


// function FooterNavNYC(props) {
//   return <>
//     {(
//       props.app == "default"
//       || (isAmexApp(props) && props.app != "American_Express_Platinum_David_LaChapelle")
//       // || (chase.isApp(props) && props.app != chase.getPath())
//      ) &&
//       <div className="row center-xs middle-xs mb4">
//         <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6">
//           <div className="text-left tickets-footer-nav">
//             <div className="">
//               <a
//                 className={"btn btn--pills small nav btn--next " + (props.app == "American_Express_Platinum_David_LaChapelle" ? 'active' : '')}
//                 href={props.app == "American_Express_Platinum_David_LaChapelle" ? null : getAmexPath()}>American
//                 Express<sup>®</sup> Platinum Admission</a>
//             </div>
//             {props.app == "default" &&
//               <>
//               <div className="">
//                 <a
//                   className={"btn btn--pills small nav btn--next " + (props.app == "American_Express_Platinum_David_LaChapelle" ? 'active' : '')}
//                   href={props.app == chase.getPath() ? null : chase.getPath()}>Chase Marriott Bonvoy<sup>®</sup> Cardmembers admission</a>
//               </div>
//               <div className="">
//                 <a
//                   className={"btn btn--pills small nav btn--next " + (props.app == "hiphop" ? 'active' : '')}
//                   href={props.app == "hiphop" ? null : "hiphop"}>Hip-Hop: Conscious, Unconscious General Admission Pre-sale</a>
//               </div>
//               </>
//             }
//           </div>
//         </div>
//       </div>
//     }
//   </>;
// }

function FooterNavNYC(props) {
  return <></>;
}
function FooterNavSTO(props) {
  return <></>;
}

export const FooterNav = branchedComponent({stockholm: FooterNavSTO, new_york: FooterNavNYC});

