function getApiKeys() {
  const keysMap = process.env.REACT_APP_FHUB_API_KEYS;
  return JSON.parse(keysMap);
}

const wp_globals = {
  "config": {
    "timber_context": {
      "site": {
        // "language": "sv-SE",
        "language": process.env.REACT_APP_LOCALE,
        // "home_url": "https://www.fotografiska.com/sto",
        // "site_url": "https://www.fotografiska.com/sto",
        // "home_url": "http://fotografiska-woo.test/sto",
        "site_url": process.env.REACT_APP_SITE_URL,
      },
      "user": {
        "data": {
          "token": null,
        },
      },


      "api_keys": getApiKeys(),
      "api_base": process.env.REACT_APP_FHUB_API_URL,
      "stripe_pk": process.env.REACT_APP_STRIPE_PK,

    },
  }
}

export default wp_globals;