export const config = {

  timeslot: {
    format: process.env.REACT_APP_TIMESLOT_FORMAT, // can be range or start
    timeFormat: process.env.REACT_APP_TIME_FORMAT, // can be range or start
  },

  currency: {
    code: process.env.REACT_APP_FHUB_CURRENCY_CODE, // SEK, USD
    format: process.env.REACT_APP_CURRENCY_FORMAT,
  },

  limits: {
    maxAvail: 99,
  },

  paymentOptions: {
    gc: {
      enabled: process.env.REACT_APP_GC_ENABLED == "true",
    }
  },

  auth: {
    cookie_token: process.env.REACT_APP_FG_AUTH_COOKIE_TOKEN || "fg_user_token",
    cookie_user: process.env.REACT_APP_FG_AUTH_COOKIE_USER || "fg_user_current_user",
    cookie_domain: process.env.REACT_APP_FG_AUTH_COOKIE_DOMAIN || ".fotografiska.com",
  },

  urls: {
    membersApp: process.env.REACT_APP_MEMBERS_APP_URL,
    becomeMember: process.env.REACT_APP_BECOME_MEMBER_URL,
    singleSignOn: process.env.REACT_APP_SINGLE_SIGN_ON_URL,
  },

  send_receipt: (process.env.REACT_APP_SEND_RECEIPT || "false") == "true",

  defaultToCurrentDateRange: () => {
    // only on 8th of march 2021
    return (new Date()).toISOString().substr(0,10) == "2021-03-08";
  }

  // showDiscounts: false,
}
