import React from "react";
import {branchedComponent} from "../../utils/branch";
import {string} from "../../utils/translate";
import Locale from "../../utils/locale";
import {isAmexInfo} from "../../utils/amex";
// import chase from '../../utils/chase';

const TicketInfoNYC = ({app, apiKey}) => {
  if (isAmexInfo({app, apiKey})) {
    return AmexTicketInfoNYC();
  }
  // if (chase.infoAdmissionVisible({app, apiKey})) {
  //   return ChaseAdmissionTicketInfoNYC();
  // }
  // if (chase.infoVisible({app, apiKey})) {
  //   return ChaseTicketInfoNYC();
  // }
  return NormalTicketInfoNYC();
};

function NYCAdmission() {
  return <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">ADMISSION</h5>
    <div className="text-width-limit1">
      <p className=""><span className="s1">Please note that only service animals will be allowed entry with their owners.</span>
      </p>
      <p className=""><span className="s1">Members enjoy unlimited free entry, anytime.</span>
      </p>
    </div>
    <section className="text-width-limit1">
      <ul className="no-bullets">
        <li className=""><span className="left">Adult</span> <span
          className="right">$24</span></li>
      </ul>
      <ul className="no-bullets">
        <li className=""><span className="left">Senior (65+)</span> <span
          className="right">$18</span></li>
      </ul>
      <ul className="no-bullets">
        <li className=""><span className="left">Youth (13-25) </span>
          <span className="right">$18</span></li>
      </ul>
      <ul className="no-bullets">
        <li className=""><span
          className="left">Military / Veteran (with ID)</span> <span className="right">$18</span></li>
      </ul>
      <ul className="no-bullets">
        <li className=""><span className="left">Children 12 and Under</span>
          <span className="right">Free</span></li>
      </ul>
    </section>

    <div className="text-width-limit1">
      <p className=""><span className="s1">
        Guided Tours available. See below for details.
      </span>
      </p>
    </div>


  </div>;
}


function NYCGroupReservation() {
  return <div className="col-xs-12 col-sm-6"><h6 className="text--uppercase">Group Reservation Details</h6>
    <div className="text-width-limit1">
      <p>
        Pending availability until reservation request is confirmed.
        Credit card payment required before visit date.
        Additional details and terms provided with confirmation.
      </p>
    </div>
  </div>;
}

function NYCDrinkTickets() {
  return <div className="col-xs-12 col-sm-6"><h6 className="text--uppercase">Drink Tickets</h6>
    <div className="text-width-limit1"><p>Make your group visit extra special and save with purchasing drink tickets
      in advance. Add Drink Tickets for your Group for only $12 per ticket inclusive of tax and gratuity. Your
      choice of a glass of bubbly, wine, beer, soda, tea, or drip coffee.</p>
      <p>Your group can enjoy their drinks anywhere in the museum, including on exhibition floors.</p>
    </div>
  </div>;
}

function NYCGroupAdmission() {
  return <div className="col-xs-12 col-sm-6">
    <h6 className="text--uppercase">Admission</h6>
    <div className="text-width-limit1">
      <p>Adult: $20&nbsp;&nbsp;<em>(reg. $24)</em><br/>
      Senior: $14&nbsp;<em>(reg. $18)</em><br/>
      Youth/Student: see below</p>

    </div>
    <h6 className="text--uppercase">Early Bird Group Rates</h6>
    <div className="text-width-limit1">
    <p>
      Adult: $17&nbsp;&nbsp;<em>(reg. $19)</em><br/>
      Senior: $8&nbsp;<em>(reg. $10)</em><br/>
      Youth/Student: see below</p>
    </div>

    <div className="text-width-limit1">
      <p>*For 10 or more guests.</p>
      <p>
        Schools and university group leaders should contact <a title="mailto:groups.ny@fotografiska.com" className="link--underlined"
                      href="mailto:groups.ny@fotografiska.com">groups.ny@fotografiska.com</a> for best rates for educational outings.
      </p>
      <p>
        For any other inquiries about groups and to book your visit, please feel free to contact us via e-mail.
      </p>
    </div>
  </div>;
}

function NYCGuidedTour() {
  return <div className="col-xs-12 col-sm-6"><h6 className="text--uppercase">Guided Tour</h6>
    <div className="text-width-limit1">
      <p>
        Create a special visit with a dedicated tour guide for your group. The tour covers one exhibition of your choice, plus an introduction about Fotografiska and its landmark building. After the tour, explore the rest of the museum on your own.
      </p>
      <ul className="dashed">
        <li>Weekday: $200 per tour guide + museum admission per guest</li>
        <li>Weekend: $250 per tour guide + museum admission per guest</li>
        <li>Conducted 10am-7pm (for best availability reserve 2 weeks in advance)</li>
      </ul>
      <p>
        One-hour Guided Tours are available for any size party of two or more guests. Due to the size of some galleries, we recommend up to 10-15 guests per tour for an optimal tour experience. A maximum of up to 20 guests per tour is possible with the chance of feeling crowded or difficulty hearing for some during portions of the tour. Groups with more than 20 guests can split into two or more tour groups.
      </p>
      <p>
        Contact <b><a title="mailto:groups.ny@fotografiska.com" className="link--underlined"
                      href="mailto:groups.ny@fotografiska.com">groups.ny@fotografiska.com</a></b> for reservations.
      </p>
    </div>
  </div>;
}

function NYCPublicTransportInfo() {
  return <div className="col-xs-12 col-sm-6"><h6 className="text--uppercase">Public Transit</h6>
    <div className="text-width-limit1"><p className="">Public transportation is a great way
      to get to Fotografiska.<span className="Apple-converted-space">&nbsp; </span>The closest subway stations are
      the 23rd Street Station on the 6 and the R/W lines</p>
      <p className="">Several city buses stop nearby, including the M1, M2, and M3</p>
      <p className="">Citibike available at the northeast corner at Park Ave S, &amp; 20th Street</p>
      <p><a
        className="link--underlined"
        href="https://www.google.com/maps/place/Fotografiska/@40.7392209,-73.9889159,17z/data=!3m1!5s0x89c259a110b4a3c9:0xf220ec9a4be963e6!4m5!3m4!1s0x89c259a110b00001:0xcd24d86e4c49a261!8m2!3d40.7392358!4d-73.986822">Plan
        Your Route</a></p>
    </div>
  </div>;
}

function NYCParkingInfo() {
  return <div className="col-xs-12 col-sm-6"><h6 className="text--uppercase">Parking</h6>
    <div className="text-width-limit1"><p className="">We are located at<span
      className="Apple-converted-space"> </span>281 Park Ave South, New York, NY 10010. If you prefer to
      drive, there are several public parking lots close by:</p>
      <p className=""><em>Icon Parking</em></p>
      <p className="">5 E 22nd St, <br/>New York, NY<br/>
        (212) 674-9002</p>
      <p className=""><em>Manhattan Parking Gramercy Corp.</em></p>
      <p className="">32 Gramercy Park S # 1, <br/>New York, NY<br/>
        (212) 533-0863</p>
    </div>
  </div>;
}

// function NYCAmexBenefits() {
//   return <div className="text-width-limit2">
//     <p>
//       The first 500 American Express® Platinum Card Members to book a Platinum ticket will receive a complimentary
//       limited-edition David LaChapelle exhibition catalog.
//     </p>
//   </div>;
// }

function NYCAmexAdditionalBenefits() {
  return <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">Additional Benefits</h5>
    <div className="text-width-limit1">
      <p>
        With a ticket reservation American Express® Platinum Card Members also enjoy:
      </p>
      <p>
        Priority Access to Verōnika.
      </p>

      <p>
        Tucked away inside Fotografiska New York is the newly opened Verōnika restaurant and Bar Verōnika.
        As an American Express® Platinum Card Member, enjoy exclusive access to priority reservations through our VIP
        reservation line.
      </p>

      <p>
        Visit The Shop at Fotografiska for one-of-a-kind books, art, design-inspired gifts, and ephemera to
        commemorate your visit. Enjoy a gift with purchase when you spend $100+ in-store through October or a 20%
        discount on your first purchase from November through December.
      </p>
    </div>
  </div>;
}

/*function NYCChaseAdditionalBenefits() {
  return <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">Additional Benefits</h5>
    <div className="text-width-limit1">
      <p>
        Benefits are available to cardmembers who present their physical Chase Marriott Bonvoy® Credit Card on-site throughout the duration of the exhibition.
      </p>
      <ul>
        <li>Save 20% off in-store on all merchandise and on all posters online</li>
        <li>One free alcoholic<sup>*</sup> or non-alcoholic drink from the museum café per ticket purchased</li>
        <li>Priority access to reservations at Verōnika restaurant</li>
        <li>Save $50 on Collector Membership (retail value $200)</li>
        <li>Save $500 on Patron Membership (retail value $2,500)</li>
      </ul>
      <p>
        <hr className="footnotes"/>
        <small><sup>*</sup>Must be 21+ years of age to consume alcoholic beverage</small>
      </p>
    </div>
  </div>;
}
*/
function NYCAmexHours() {
  return <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">HOURS</h5>
    <div className="text-width-limit1">
      <div>
        <p className="">Sunday to Wednesday<br/>
          10:30 AM ― 9:00 PM</p>
        <p className="">Thursday to Saturday<br/>
          10:30 AM ― 11:00 PM</p>

        <p className="">The David LaChapelle <em>make Believe</em> exhibition is open to the public from September
          9, 2022 through January 8, 2023.
          {/* Fotografiska New York is open to the public and to members with guidelines in place for the safety and
          well-being of our guests and staff. */}
        </p>

        <p className="">Last entry is one hour before closing.</p>
      </div>
      <div>
        For any press-related inquiries, please contact <a
          href="mailto:press@fotografiska.com" className="link--underlined">press@fotografiska.com</a>. Press entry must be arranged in advance with the press department.
      </div>
    </div>
  </div>;
}

/*
function NYCChaseHours() {
  return <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">HOURS</h5>
    <div className="text-width-limit1">
      <div>
        <p className="">Sunday to Wednesday<br/>
          10:30 AM ― 9:00 PM</p>
        <p className="">Thursday to Saturday<br/>
          10:30 AM ― 11:00 PM</p>

        <p className="">Experience Hip-Hop: Conscious, Unconscious, a photography exhibition documenting the 50-year evolution
        of hip-hop from nascent cultural movement to global phenomenon, on view at Fotografiska New York from January 26th through May 20, 2023.
        </p>

        <p className="">Last entry is one hour before closing.</p>
      </div>
      <div>
        For any press-related inquiries, please contact <a
          href="mailto:press@fotografiska.com" className="link--underlined">press@fotografiska.com</a>. Press entry must be arranged in advance with the press department.
      </div>
    </div>
  </div>;
}
*/

function NYCFloorClosures() {
  return <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">Floor Closures</h5>
    <div className="text-width-limit1">
        <p className="">
          To bring our community fantastic events sometimes our exhibitions and museum floors are temporarily unavailable:
        </p>
        <p className="">
          1/16 – 1/26: Museum closed for exhibition install.
        </p>
    </div>
  </div>;
}

// function NYCHealthSafety() {
//   return <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">HEALTH &amp; SAFETY</h5>
//     <div className="text-width-limit1">
//         <p className="">
//           Fotografiska New York is open to the public and to members with guidelines in place for the safety and well-being of our guests and staff. We’ve maintained our heightened cleaning and sanitization measures throughout the museum and encourage guests and members to book timed entry tickets in advance.
//         </p>
//         <p className="">
//           In accordance with Key to NYC,  visitors are no longer required to show proof of vaccine upon entry. However, we do highly encourage guests to receive their vaccine boosters, stay masked indoors, and maintain a respectful distance between others during their visit.
//         </p>
//     </div>
//   </div>;
// }


function NYCHours() {
  return <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">HOURS</h5>
    <div className="text-width-limit1">
      <div>
      <p className="">Sunday to Thursday<br/>
          10:30 AM ― 9:00 PM</p>
        <p className="">Friday to Saturday<br/>
          10:30 AM ― 11:00 PM</p>

        {/* <p className="">Fotografiska New York is open to the public and to members with guidelines in place for
          the
          safety and
          well-being of our guests and staff. We encourage guests and members book timed entry tickets in
          advance.</p> */}

        <p className="">Last entry is one hour before closing.</p>
      </div>
      <div>
        <p>
        For any press-related inquiries, please contact <a
          href="mailto:press@fotografiska.com" className="link--underlined">press@fotografiska.com</a>. Press entry must be arranged in advance with the press department.
        </p>
      </div>
    </div>
  </div>;
}

/*
function ChaseTicketInfoNYC() {
  return <div className="text-left mt6 content">
    <h3 className="detail-hero__title">Visit Fotografiska</h3>
    <section className="row">
      <NYCChaseAdditionalBenefits/>
      <NYCChaseHours/>
    </section>
    <section className="row">
      <NYCChasePresaleAdmission/>
      <NYCHealthSafety/>
    </section>

    <h5 className="text--uppercase">Group Visits</h5>
    <section className="row">
      <NYCGroupAdmission/>
      <NYCGuidedTour/>
      <NYCDrinkTickets/>
      <NYCGroupReservation/>
    </section>

    <h5 className="text--uppercase">Plan Your Visit</h5>
    <section className="row">
      <NYCParkingInfo/>

      <NYCPublicTransportInfo/>

    </section>

  </div>;
}
*/

/*const NYCChasePresaleAdmission = () => <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">ADMISSION</h5>
    <div className="text-width-limit1">
      <p className=""><span className="s1">Members enjoy unlimited free entry, anytime.</span>
      </p>
    </div>
    <section className="text-width-limit1">
      <ul className="no-bullets">
        <li className=""><span className="left">Adult</span> <span
          className="right">$31</span></li>
      </ul>
      <ul className="no-bullets">
        <li className=""><span className="left">Senior (62+)</span> <span
          className="right">$21</span></li>
      </ul>
      <ul className="no-bullets">
        <li className=""><span className="left">Student (with ID) </span>
          <span className="right">$21</span></li>
      </ul>
      <ul className="no-bullets">
        <li className=""><span
          className="left">Military / Veteran (with ID)</span> <span className="right">$21</span></li>
      </ul>
      <ul className="no-bullets">
        <li className=""><span className="left">Children 6 and Under</span>
          <span className="right">Free</span></li>
      </ul>
    </section>
    }
  </div>;
*/
/*
function ChaseAdmissionTicketInfoNYC() {
  return <div className="text-left mt6 content">
    <h3 className="detail-hero__title">Visit Fotografiska</h3>
    <section className="row">
      <NYCChasePresaleAdmission/>
      <NYCChaseHours/>
    </section>
    <section className="row">
      <NYCHealthSafety/>
    </section>

    <h5 className="text--uppercase">Group Visits</h5>
    <section className="row">
      <NYCGroupAdmission/>
      <NYCGuidedTour/>
      <NYCDrinkTickets/>
      <NYCGroupReservation/>
    </section>

    <h5 className="text--uppercase">Plan Your Visit</h5>
    <section className="row">
      <NYCParkingInfo/>

      <NYCPublicTransportInfo/>

    </section>

  </div>;
}
*/

function AmexTicketInfoNYC() {
  return <div className="text-left mt6 content">
    <h3 className="detail-hero__title">Visit Fotografiska</h3>
    {/* <NYCAmexBenefits/> */}
    <section className="row">
      <NYCAmexAdditionalBenefits/>
      <NYCAmexHours/>
    </section>
    <section className="row">
      <NYCAdmission/>
    </section>
    <section className="row">
      {/* <NYCFloorClosures/> */}
      {/* <NYCHealthSafety/> */}
    </section>

    <h5 className="text--uppercase">Group Visits</h5>
    <section className="row">
      <NYCGroupAdmission/>
      <NYCGuidedTour/>
      <NYCDrinkTickets/>
      <NYCGroupReservation/>
    </section>

    <h5 className="text--uppercase">Plan Your Visit</h5>
    <section className="row">
      <NYCParkingInfo/>

      <NYCPublicTransportInfo/>

    </section>

  </div>;
}


function NormalTicketInfoNYC() {
  return <div className="text-left mt6 content">
    <h3 className="detail-hero__title">Visit Fotografiska</h3>
    <section className="row">
      <NYCAdmission/>
      <NYCHours/>
    </section>
    <section className="row">
      {/* <NYCFloorClosures/> */}
      {/* <NYCHealthSafety/> */}
    </section>


    <h5 className="text--uppercase">Group Visits</h5>
    <section className="row">
      <NYCGroupAdmission/>
      <NYCGuidedTour/>
      <NYCDrinkTickets/>
      <NYCGroupReservation/>
    </section>

    <h5 className="text--uppercase">Plan Your Visit</h5>
    <section className="row">
      <NYCParkingInfo/>
      <NYCPublicTransportInfo/>
    </section>
  </div>;
}

function TicketInfoSTOSv(props) {
  return <div className="text-left mt6 content">
    <h4 className="detail-hero__title">Besök Fotografiska</h4>
    <section className="row mt5">
      <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">BILJETTER & PRISER</h5>
        <div className="text-width-limit1">
          {/* <section className="text-width-limit1">
            <ul className="no-bullets">
              <li className="">
                <span className="left">Vuxen: 210&nbsp;kr (drop in 225&nbsp;kr)</span>
              </li>
            </ul>
            <ul className="no-bullets">
              <li className="">
                <span className="left">Student: 170&nbsp;kr (drop in 195&nbsp;kr)</span>
              </li>
            </ul>
            <ul className="no-bullets">
              <li className="">
                <span className="left">Senior: 170&nbsp;kr (drop in 195&nbsp;kr)</span>
              </li>
            </ul>
            <ul className="no-bullets">
              <li className="">
                <span className="left">Barn (0-12 år): Gratis</span>
              </li>
            </ul>
          </section> */}
          <p>
            Entrébiljetter kan även köpas drop in på plats. Förbokar du biljett online får du dock ett mer fördelaktigt pris. Medlemmar har alltid gratis entré. Observera att förköpta biljetter återbetalas ej. Gratis inträde för barn till och med 12 år och reducerat pris för ungdomar från 13 år, studenter och pensionärer. Boka ditt besök och förbered dig på att visa giltig legitimation vid entrén.
          </p>
          <p>
            Biljetter för specifika events kräver förhandsbokning, drop in endast möjligt om det framgår i eventbeskrivningen.
          </p>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">ÖPPETTIDER</h5>
        <div className="text-width-limit1">
          <div className="p">
            <span className="float-left">
              <svg xmlns="http://www.w3.org/2000/svg"
                   height="12px" viewBox="0 0 24 24"
                   width="12px" fill="#000000"><path
                d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>
            </span>
            <div className="ml3"><p className="">Öppet 10 – 23.<br/>
              Måndag–söndag.
            </p>
            </div>
          </div>
          <p className="p">
            Scanna QR-koden i entréspärren.
          </p>
        </div>
      </div>
    </section>
  </div>;
}

function TicketInfoSTOEn(props) {
  return <div className="text-left mt6 content">
    <h4 className="detail-hero__title">Visit Fotografiska</h4>
    <section className="row mt5">
      <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">Tickets &amp; Prices</h5>
        <div className="text-width-limit1">
          {/* <section className="text-width-limit1">
            <ul className="no-bullets">
              <li className="">
                <span className="left">Adult: 210&nbsp;sek (drop in 225&nbsp;sek)</span>
              </li>
            </ul>
            <ul className="no-bullets">
              <li className="">
                <span className="left">Student: 170&nbsp;sek (drop in 195&nbsp;sek)</span>
              </li>
            </ul>
            <ul className="no-bullets">
              <li className="">
                <span className="left">Senior: 170&nbsp;sek (drop in 195&nbsp;sek)</span>
              </li>
            </ul>
            <ul className="no-bullets">
              <li className="">
                <span className="left">Children (0-12 years): Free</span>
              </li>
            </ul>
          </section> */}
          <p>
            Drop in-tickets for general admission are available at the entry, but if you book online you will get a more advantageous price. Members enjoy unlimited free entry, anytime. Please note that bought tickets are not refundable. Free admission for children up to and including 12 and reduced price for students and seniors. Book your visit and be ready to show valid identification at the entrance.
          </p>
          <p>
            Tickets for specific events require online booking in advance, drop-in only possible if stated in the event description.
          </p>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6"><h5 className="text--uppercase">OPENING HOURS</h5>
        <div className="text-width-limit1">
          <div className="p">
            <span className="float-left">
              <svg xmlns="http://www.w3.org/2000/svg"
                   height="12px" viewBox="0 0 24 24"
                   width="12px" fill="#000000"><path
                d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>
            </span>
            <div className="ml3"><p className="">Open 10 AM – 11 PM.<br/>
              Monday–Sunday.
            </p>
            </div>
          </div>
          <div className="p">
            <p>
              Scan the QR code in the entrance gate.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>;
}

export const TicketInfo = branchedComponent({
  stockholm: Locale.getShortLocale()=='en' ? TicketInfoSTOEn : TicketInfoSTOSv,
  new_york: TicketInfoNYC
});
