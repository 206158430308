import { Component } from "react";
import { CMSBlocks } from "./api";
import ReactMarkdown from "react-markdown";
import ReactHtmlParser from "react-html-parser";
import Locale from "../utils/locale";

export class Block extends Component {

  constructor(props) {
    super(props);
    const slugs = typeof (this.props.slugs) == 'string' ? [this.props.slugs] : this.props.slugs;

    this.state = {
      contents: [],
      slugs: slugs,
    };
  }

  async componentDidMount() {
    const response = await CMSBlocks.listCMSBlocks(this.props.apiKey, this.state.slugs);
    const blocks = (response?.data?.cms_blocks || []).filter((b) => b.visible);
    const contents = blocks.reduce((acc,block) => {
      const items = block.contents.filter(c => c.content != null && c.content !== '' && c.language === Locale.getShortLocale());
      return [
        ...acc,
        ...items
      ]
    }, [])
    this.setState({
      contents
    });
  }

  renderContent(c) {
    switch (c.format) {
      case "markdown":
        return <ReactMarkdown children={c.content} />;
      case "html":
        return (
          <div>{ReactHtmlParser(c.content)}</div>
        );
      default:
        return <div>{c.content}</div>;
    }
  }

  render() {
    const { contents } = this.state;

    if (!contents.length) {
      return <></>;
    }

    return (
      <div className="content">
        { contents.map(c => <div key={c.id}>{this.renderContent(c)}</div>) }
      </div>
    );
  }
}

export default Block;
