import React from 'react';
import Chase_Bonvoy_Line from "images/chase/Chase_Bonvoy_Line.png";

const chaseSponsorship = () => <div className="chase__line">
    <div className="row center-xs middle-xs">
      <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6">
        <div className="inner">
          <span>In Partnership with</span>
          <div className="image">
            <img src={Chase_Bonvoy_Line}/>
          </div>
        </div>
      </div>
    </div>
  </div>;

export default chaseSponsorship;