import React from "react";
import { dataLayerVariable } from "utils/dataLayer";

const id = process.env.REACT_APP_GTAG_ID;

export const GTagAds = (props) => {
  React.useEffect(() => {
    if (id != null) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag("js", new Date());
      gtag("config", id);
      window.gtag = gtag;

      Object.entries(props).forEach(([key, value]) => {
        dataLayerVariable(key, value);
      });

      gtagConversion("pageview", {});
    }
  });
  return null;
};

export const gtagConversion = (event, props = {}) => {
  if (window.gtag !== undefined) {
    window.gtag("event", event, props);
  } else {
    console.warn("no gtag code found");
  }
};
