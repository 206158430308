import React from "react";
import ReactPixel from 'react-facebook-pixel';

export const FacebookPixel = () => {
  React.useEffect(() => {
    const pixelIdString = process.env.REACT_APP_FB_PIXEL_IDS;
    const pixelIds = pixelIdString ? pixelIdString.split(',').map(i => i.trim()).filter(i => i!='') : [];
    if (pixelIds.length>0) {
      // const advancedMatching = {
      //   em: 'some@email.com'
      // }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      for (let pixelId of pixelIds) {
        ReactPixel.init(pixelId, null, options);
      }
      ReactPixel.pageView();

      // Router.events.on('routeChangeComplete', () => {
      //   ReactPixel.pageView();
      // });
    }
  });
  return null;
};

export const fbEvent = (event, props = {}) => {
  ReactPixel.track(event, props);
}
