import React, { Component } from "react";
import axios from "axios";

import { get } from "../utils/globals";
import CheckoutModal from "./checkout-modal";
import { strings } from "../utils/translate";
import { getApiHeaders } from "./api";
import { formatTimeslotTimeRange } from "./timeslot";

import DatePicker, { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { parseISO } from "date-fns";
import {
  getProductGroupKey,
  parseProductGroupKey,
  ProductHelper,
} from "./product";
import { config } from "./config";
import {
  formatPriceCurrency,
  formatPriceInCurrencyRange,
  isZeroTotal,
  roundPrice,
} from "./util";
import { ReactComponent as LoadingImg } from "images/loader.svg";
import { UserMenu } from "./userMenu";
import { LangSwitcher } from "./langSwitcher";
import { fbEvent } from "../utils/facebookPixel";
import { gtagConversion } from "../utils/gtagAds";

import { Nav } from "./nav";
import Locale from "../utils/locale";
import {TicketInfo} from "./content/ticketInfo";
import {Logo} from "./logo";
import {isAmexBanner, isAmexSponsorship} from "../utils/amex";
import {isAdmissionTicketsPage, AdmissionTicketsInfo} from "./content/admissionTicketsInfo";
import Amex_Banner from "images/amex/Ticket_Banner_05.jpg";
import Amex_Line from "images/amex/AMEX_logo.png";
import { FooterNav } from "./footerNav";
import TopBar from "./topBar";
import chase from "utils/chase";
import ChaseSponsorship from "./chase/chase-sponsorship";
import ChaseBanner from "./chase/chase-banner";
import { dataLayerRemoveVariable } from "utils/dataLayer";

registerLocale("sv", sv);

class Cart extends Component {
  constructor() {
    super();
    this.state = {
      cart: [],
      products: [],
      productGroups: [],
      productToGroupKeys: {},
      groupToProductIds: {},
      timeslotGroupToProductIds: {},
      timeslots: {},
      dates: {},
      plans: [],
      loading: true,
    };
    this.priceRangesCache = {};
    this.additionalInfo.bind(this);
  }

  componentDidMount() {
    window.history.replaceState(null, null, this.props.basePath);
    this.setState({ translations: strings() });
    this.loadInventory().then(() => {
      this.setState({ loading: false });
    });
  }

  async loadInventory() {
    return new Promise((resolve, reject) => {
      this._getProducts()
        .then((products) =>
          this._filterProducts(products, this.props.filterProductIds)
        )
        // .then(this.sortProducts)
        .then((products) => {
          const productsByGroups = this._groupProductsByTimeGroups(products);
          // const productsByTimeslotGroupId = this._groupProductsByTimeslotGroupId(products);
          // const timeslotGroupIds = Object.keys(productsByTimeslotGroupId).filter(i => i != null).map(i => parseInt(i));
          const timeslotGroupIds =
            this._getTimeslotGroupIdsFromProducts(products);
          this._getTimeslotGroups(timeslotGroupIds).then((timeslotGroups) => {
            const productGroups = this._groupProducts(
              productsByGroups,
              timeslotGroups
            );

            const productToGroupKeys =
              this.getProductToGroupKeys(productGroups);
            const groupToProductIds = this.getGroupToProductIds(productGroups);
            const timeslotGroupToProductIds =
              this.getTimeslotGroupToProductIds(products);
            this.setState({
              products,
              productGroups,
              productToGroupKeys,
              groupToProductIds,
              timeslotGroupToProductIds,
              productsByGroups,
            });

            this._populateCart(products);
            resolve(true);
          });
        })
        .catch((error) => {
          console.warn(error);
          this.setState({ error: error.message });
          resolve(false);
        });
    });
  }

  // sortProducts(products) {
  //   return products.sort( (a, b) => {
  //     return a.order == b.order ? 0 : (a.order > b.order ? 1 : -1)
  //   })
  // }

  _groupProducts(productsByGroups, timeslotGroups) {
    const timeslotGroupsById = timeslotGroups.reduce((acc, timeslotGroup) => {
      return {
        ...acc,
        [timeslotGroup.id]: timeslotGroup,
      };
    }, {});
    return Object.entries(productsByGroups)
      .reduce((acc, [groupKey, products]) => {
        const {
          timeslot_group_id,
          timeslot_valid_since,
          timeslot_valid_until,
        } = parseProductGroupKey(groupKey);
        // timeslotGroupId = timeslotGroupId == "null" || timeslotGroupId == null ? null : parseInt(timeslotGroupId);
        const timeslotGroup = timeslot_group_id
          ? timeslotGroupsById[timeslot_group_id]
          : null;
        const dateTimes = timeslotGroup
          ? this.timeslotsToDatesAndTimes(
              timeslotGroup.timeslots,
              timeslot_valid_since,
              timeslot_valid_until
            )
          : null;
        if (timeslotGroup && Object.values(dateTimes).length == 0) {
          // timeslot required, but no timeslots available .. skip group
          return acc;
        } else {
          return [
            ...acc,
            {
              timeslotGroup: timeslotGroup,
              timeslot_valid_since,
              timeslot_valid_until,

              dateTimes: dateTimes,

              timeslotGroupId: timeslot_group_id,

              groupKey,
              products: products, //timeslot_group_id ? this.sortProductsByPriceDesc(products) : products,
            },
          ];
        }
      }, [])
      .reduce((acc, group) => [...acc, { ...group, index: acc.length }], []); // add index
  }

  // sortProductsByPriceDesc(products) {
  //   return products.sort((a, b) => a.price == b.price ? 0 : (a.price > b.price ? -1 : 1));
  // }

  timeslotsToDatesAndTimes(
    timeslots,
    timeslot_valid_since,
    timeslot_valid_until
  ) {
    const validSinceTime = timeslot_valid_since
      ? parseISO(timeslot_valid_since + "Z")
      : null;
    const validUntilTime = timeslot_valid_until
      ? parseISO(timeslot_valid_until + "Z")
      : null;

    // console.log('validUntilTime', validUntilTime)
    return timeslots
      .filter(({ valid }) => valid)
      .filter(({ timeslot_date, timeslot_start, timeslot_end }) => {
        const timeslotStartTime = parseISO(
          `${timeslot_date}T${timeslot_start}Z`
        );
        const timeslotEndTime = parseISO(`${timeslot_date}T${timeslot_end}Z`);
        if (validSinceTime && timeslotStartTime < validSinceTime) {
          return false;
        }
        if (validUntilTime && timeslotEndTime > validUntilTime) {
          return false;
        }
        return true;
      })
      .reduce((acc, timeslot) => {
        const { timeslot_date, timeslot_start, timeslot_end, left_usages, id } =
          timeslot;

        return {
          ...acc,
          [timeslot_date]: [
            ...(acc[timeslot_date] || []),
            {
              id,
              label: formatTimeslotTimeRange(timeslot),
              discounts: timeslot.discounts,
              left_usages,
            },
          ],
        };
      }, {});
  }

  _getTimeslotGroups(timeslotGroupIds) {
    if (timeslotGroupIds.length == 0) {
      return Promise.resolve([]);
    }
    const { context, headers } = getApiHeaders(this.props.apiKey);

    return Promise.all(
      timeslotGroupIds.map((id) => {
        return axios
          .get(`${context.api_base}/timeslot_groups/${id}`, { headers })
          .then((response) => response.data.data);
      })
    );

    // const params = {
    //   limit: 1000
    // }
    //
    // return axios.get(`${config.api_base}/timeslot_groups`, {headers, params}).then(response => response.data.data)
    //   .then(timeslotGroups => timeslotGroups.filter(timeslotGroup => timeslotGroupIds.includes(timeslotGroup.id)))
  }

  _populateCart(products) {
    /*const {cart} = this.state;
    for (let i = 0; i < products.length; i++) {
      // FIXME: need to check if product exists in productGroups.. as it may be not available due to restrictions or missing timeslots
      if (products[i].slug === 'day-ticket') {
        for (let j = 0; j < 1; j++) {
          cart.push(products[i]);
          this.setState({cart});
        }
      }
    }*/
  }

  _groupProductsByTimeslotGroupId(products) {
    return products.reduce((acc, product) => {
      const {
        ticket_type: { timeslot_group_id } = { timeslot_group_id: null },
      } = product;
      acc[timeslot_group_id] = [...(acc[timeslot_group_id] || []), product];
      return acc;
    }, {});
  }

  async _filterProducts(products, filterProductIds = null) {
    if (filterProductIds !== null) {
      const filterSlug = (slug) => slug.replace(/[.]/, "-");
      const filterProductSlugs = filterProductIds.map(filterSlug);

      return products.filter(({ id, slug }) => {
        return (
          filterProductIds.includes(id) ||
          filterProductIds.includes("" + id) ||
          filterProductSlugs.includes(filterSlug(slug))
        );
      });
    } else {
      return products;
    }
  }

  async _getProducts() {
    let { context, headers } = getApiHeaders(this.props.apiKey);
    const params = {
      limit: 1000,
      type: "ticket",
      sort: "order",
      dir: "asc",
    };

    return await axios
      .get(`${context.api_base}/products`, {
        headers,
        params,
      })
      .then((response) => response.data.data);
  }

  _emptyCart() {
    this.setState({ cart: [] });
  }

  _remove(product) {
    const { cart } = this.state;
    const foundIndex = cart.map((p) => p.id).indexOf(product.id);

    if (foundIndex > -1) {
      cart.splice(foundIndex, 1);
      this.setState({ cart });
    } else {
      console.warn("no product of ", product.id, "in cart");
    }
  }

  _add(product) {
    const { cart } = this.state;
    cart.push({
      ...product,
    });
    this.setState({ cart });
    fbEvent("AddToCart", {
      value: product.price,
      currency: config.currency.code,
      content_name: product.name,
      content_category: this.props.app,
      content_type: "product", // Required for Dynamic Product Ads
      content_ids: [product.slug], // Required for Dynamic Product Ads
    });
  }

  _totalAmountInCart() {
    const { cart } = this.state;
    return cart.length;
  }

  _amountInCart(product) {
    const { cart } = this.state;

    const amount = cart.filter((p) => p.id === product.id).length;
    return amount;
  }

  _ticketsInCart(product) {
    return this._amountInCart(product) * (product.ticket_quantity || 1);
  }

  _totalWorth() {
    const { cart } = this.state;
    let val = 0;

    cart.map((item) => {
      val += this.calcProductPrice(item);
    });

    return roundPrice(val);
  }

  _hideModal() {
    window.history.replaceState(null, null, this.props.basePath);
    dataLayerRemoveVariable("item_ids");
    gtagConversion("pageview", {}); // used by snap pixel
    this.setState({ showCheckoutModal: false });
  }

  _showModal() {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    this.setState({ showCheckoutModal: true });
    fbEvent("InitiateCheckout", this.getFbPurchase());
  }

  additionalInfo(product, groupKey, { amountInCart }) {
    const config = get(["config", "timber_context"], false);
    const { translations } = this.state;

    if (
      product.product_restriction &&
      product.product_restriction.meta_values.length
    ) {
      let items = product.product_restriction.meta_values.filter(
        (value) => value.locale == Locale.getShortLocale()
      );
      if (items.length) {
        return <span className="small help-text">{items[0].value}</span>;
      }
    }

    const available_for_purchase = ProductHelper.getAvailableOn(
      product,
      this.getSelectedDate(groupKey)
    );
    if (available_for_purchase && available_for_purchase <= amountInCart) {
      return (
        <span className="small help-text">
          {translations["maximum tickets reached"]}
        </span>
      );
    } else {
      if (
        this.isTimeslotRequiredForGroup(groupKey) &&
        ProductHelper.isMemberPriceDisabled(
          product,
          this.getSelectedDate(groupKey)
        )
      ) {
        return (
          <span className="small help-text">
            {translations["ticket has been reserved on this date"]}
          </span>
        );
      }
      // if (available_for_purchase) {
      // return (
      //   <span className="small help-text">{translations['available tickets']}: {available_for_purchase}</span>
      // )
      // }
    }

    return "";
  }

  renderProductInfo(
    product,
    groupKey,
    timeslotGroup,
    ticketsInCartInTimeslot,
    ticketsInCartInGroup
  ) {
    const maxAvail = ProductHelper.getMaxAvailableOn(
      product,
      this.getSelectedDate(groupKey)
    );
    // const leftInTimeslot = this.getLeftUsagesInSelectedTimeslot(groupKey, timeslotGroup);

    const timeslotId = this.getSelectedTimeslotId(groupKey);
    const leftInTimeslot = timeslotId
      ? this.getLeftUsagesByTimeslotId(timeslotGroup, timeslotId)
      : null;
    const leftInGroup =
      this.getMaxPossibleLeftUsagesByTimeslotGroup(timeslotGroup);

    const productPrice = this.calcProductPrice(product);

    const productPriceRange = product.hide_price
      ? null
      : this.formatCurrentProductPriceRange(product);

    // if (leftInTimeslot !== null) {
    //   maxAvail = Math.min(maxAvail, leftInTimeslot);
    // }
    const amountInCart = this._amountInCart(product);

    const amountInCartOverAvail = amountInCart > maxAvail;
    const plusDisabled =
      amountInCart >= maxAvail || // more tickets than available
      // (this._ticketsInCart(product) + (product.ticket_quantity || 1) > maxAvail) || // more tickets than available
      product.can_purchase == false || // purchase limited on the backend
      (leftInTimeslot !== null &&
        ticketsInCartInTimeslot != null &&
        ticketsInCartInTimeslot + (product.ticket_quantity || 1) >
          leftInTimeslot) ||
      (leftInGroup !== null &&
        ticketsInCartInGroup != null &&
        ticketsInCartInGroup + (product.ticket_quantity || 1) > leftInGroup); // cannot add any more tickets due to limit
    return (
      <div
        className={
          "input-counter-row" + (amountInCartOverAvail ? " invalid" : "")
        }
        key={product.id}
      >
        <div
          className={`input-counter ${
            product.can_purchase == false ? "disabled" : ""
          }`}
        >
          <label>
            {product.name}
            {productPriceRange ? ": " + productPriceRange : ""}
          </label>
          <div className="input-counter__container">
            <span
              className={`input-counter__minus ${
                amountInCart === 0 ? "disabled" : ""
              }`}
              onClick={() => this._remove(product)}
            ></span>
            <span
              className={`input-counter__value align-middle text-center ${
                amountInCart === 0 ? "disabled" : ""
              }`}
              data-price={productPrice}
            >
              {amountInCart}
            </span>
            <span
              className={`input-counter__plus ${
                plusDisabled ? "disabled" : ""
              }`}
              onClick={() => this._add(product)}
            ></span>
          </div>
        </div>
        {this.additionalInfo(product, groupKey, { amountInCart })}
      </div>
    );
  }

  getSelectedTimeslot(groupKey) {
    const selectedTimeslotId = this.getSelectedTimeslotId(groupKey);
    if (!selectedTimeslotId) {
      return null;
    }
    const productGroup = this.getProductGroupByKey(groupKey);
    return this.getTimeslotById(productGroup.timeslotGroup, selectedTimeslotId);
  }

  getProductGroupByKey(groupKey) {
    const { productGroups } = this.state;
    return productGroups.find(
      (productGroup) => productGroup.groupKey == groupKey
    );
  }

  getSelectedTimeslotId(groupKey) {
    const { timeslots } = this.state;
    const ticketsInCartByGroup = this.ticketsInCartByGroup(groupKey);
    if (ticketsInCartByGroup <= 0) {
      // if no tickets added, we don't look at the selected timeslot and date/time controls are hidden
      return null;
    }
    return groupKey in timeslots && timeslots[groupKey] != null
      ? timeslots[groupKey]
      : "";
  }

  selectTimeslot(groupKey, timeslot_id) {
    const timeslots = {
      ...this.state.timeslots,
      [groupKey]:
        timeslot_id == "" || timeslot_id == null ? null : parseInt(timeslot_id),
    };
    this.setState({
      timeslots,
    });
  }

  getSelectedDate(groupKey) {
    const { dates } = this.state;
    return groupKey in dates && dates[groupKey] != null ? dates[groupKey] : "";
  }

  selectDate(groupKey, dateTimes, date) {
    date = Cart.dateToString(date);
    const dates = {
      ...this.state.dates,
      [groupKey]: date == "" ? null : date,
    };
    this.setState({
      dates,
    });

    const selectedTimeslotId = this.getSelectedTimeslotId(groupKey);
    if (selectedTimeslotId) {
      if (
        !date ||
        dateTimes[date].find(({ id }) => id == selectedTimeslotId) == null
      ) {
        this.selectTimeslot(groupKey, null);
      }
    }
  }

  getGroupDates(dateTimes) {
    return dateTimes ? Object.keys(dateTimes) : [];
  }

  getGroupTimes(groupKey, dateTimes) {
    const selectedDate = this.getSelectedDate(groupKey);
    if (selectedDate) {
      return dateTimes[selectedDate];
    } else {
      return [];
    }
  }

  isTimeslotRequiredForGroup(groupKey) {
    const { timeslot_group_id } = parseProductGroupKey(groupKey);
    if (!timeslot_group_id) {
      return false;
    }
    const productIds = this.getGroupProductIds(groupKey);
    const productIdsRequireTimeslot = this.getCartProductIds().filter(
      (cartProductId) => {
        return productIds.includes(cartProductId);
      }
    );
    return productIdsRequireTimeslot.length > 0;
  }

  /*getTimeslotGroupProductIds(timeslotGroup) {
    const {groupToProductIds} = this.state;
    if (!timeslotGroup.id in groupToProductIds) {
      return [];
    }
    return groupToProductIds[timeslotGroup.id];
  }*/

  renderTimeslotSelector(groupKey, timeslotGroup, dateTimes, limitReached) {
    const { translations, productGroups } = this.state;
    const moreThanOneGroup = productGroups.length > 1;

    if (dateTimes && this.isTimeslotRequiredForGroup(groupKey)) {
      const selectedTimeslotId = this.getSelectedTimeslotId(groupKey);

      const selectedProducts = this.getProductsInCartByGroup(groupKey);

      return (
        <div className="input-timeslot-row">
          <div className="timeslot">
            <label>
              {config.timeslot.format == "arrival"
                ? translations["Date and time of arrival"]
                : moreThanOneGroup
                ? translations["Date and time for the above"]
                : translations["Date and time"]}
            </label>
            <div className="timeslot__container">
              <div className="timeslot__field">
                {this.renderDatePicker(groupKey, dateTimes)}
              </div>
              <div className="timeslot__field">
                <div className="timeslot__field__select_time select">
                  <select
                    className={selectedTimeslotId == "" ? "placeholder" : ""}
                    value={selectedTimeslotId}
                    onChange={(event) =>
                      this.selectTimeslot(groupKey, event.target.value)
                    }
                  >
                    <option value={""}>{translations["Select time"]}</option>
                    {this.getGroupTimes(groupKey, dateTimes).map(
                      ({ label, id, discounts, left_usages }) => {
                        const pricingText = this.formatCurrentProductsPrices(
                          selectedProducts,
                          timeslotGroup,
                          id
                        );

                        const itemSelected = selectedTimeslotId == id;
                        const timeAvailLabel =
                          this.getTimeAvailDescription(left_usages);
                        return (
                          <option
                            value={id}
                            key={id}
                            disabled={left_usages !== null && left_usages <= 0}
                          >
                            {label}
                            {pricingText &&
                              ("   " + pricingText).replace(/ /g, "\u00a0")}
                            {timeAvailLabel &&
                              ("   " + timeAvailLabel).replace(/ /g, "\u00a0")}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {
            limitReached && (
              <span className="small help-text">
                {translations["maximum tickets reached"]}
              </span>
            )
            // (
            //   <span className="small help-text">{translations['available tickets']}: {this.getLeftUsagesInSelectedTimeslot(timeslotGroup)}</span>
            // ) :
          }
        </div>
      );
    } else {
      return "";
    }
  }

  isCartValid() {
    const { cart } = this.state;

    if (cart.length == 0) {
      return false;
    }

    // if (this.getZeroPricedProductsInCartWithoutPaid()>0) {
    //
    //   return false;
    // }

    if (!this.areTimeslotsChosen()) {
      return false;
    }

    if (!this.isCartValidForAllGroups()) {
      return false;
    }

    return true;
  }

  getZeroPricedProductsInCartWithoutPaid() {
    const { cart, productGroups } = this.state;
    const zeroPricedProductIds = [
      ...new Set(cart.filter(({ price }) => price == 0).map(({ id }) => id)),
    ];
    const zeroPricedSiblingItems = zeroPricedProductIds.reduce(
      (acc, zeroPricedProductId) => {
        const siblingProductIds = productGroups
          .find(({ products }) => {
            return products.find(({ id }) => id == zeroPricedProductId) != null;
          })
          .products.filter(
            ({ id, price }) => id != zeroPricedProductId && price > 0
          )
          .map(({ id }) => id);
        return {
          ...acc,
          [zeroPricedProductId]: cart.filter(({ id }) =>
            siblingProductIds.includes(id)
          ).length,
        };
      },
      {}
    );
    const zeroPricedProductsWithoutPaidProduct = Object.entries(
      zeroPricedSiblingItems
    ).filter(
      ([zeroPricedProductId, nonZeroProductsInCart]) =>
        nonZeroProductsInCart == 0
    );
    return zeroPricedProductsWithoutPaidProduct.length;
  }

  areTimeslotsChosen() {
    const { timeslots } = this.state;
    const cartGroupKeys = this.getCartGroupKeys().filter((groupKey) =>
      this.groupKeyHasTimeslotGroup(groupKey)
    );
    const missingGroupKeys = cartGroupKeys.filter((groupKey) => {
      return !(groupKey in timeslots) || timeslots[groupKey] == null;
    });
    return missingGroupKeys == 0;
  }

  getCartProductIds() {
    const { cart } = this.state;
    return [...new Set(cart.map(({ id }) => id))];
  }

  render() {
    const {
      cart,
      timeslots,
      productGroups,
      plans,
      translations,
      loading,
      error,
    } = this.state;
    const context = get(["config", "timber_context"]);

    const timeslotRequiredMap = Object.fromEntries(
      productGroups.map(({ groupKey }) => [
        groupKey,
        this.isTimeslotRequiredForGroup(groupKey),
      ])
    );

    const cartValid = this.isCartValid();
    const total = this._totalWorth();
    return loading ? (
      <div className="flex items-center justify-center text-center my2">
        <LoadingImg />
      </div>
    ) : (
      <>
        <TopBar apiKey={this.props.apiKey} />
        <div className="app-wrapper">
          {!this.state.showCheckoutModal && (
            <>
              <div className="row center-xs middle-xs">
                {/*<div className="col-xs-12 col-sm-9 col-md-7 col-lg-6 m-mb4">*/}
                <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6">
                  <div className="m-mb0 mb3">
                    <div className="float-right">
                      <div className="flex items-start">
                        {/*<LangSwitcher/>*/}
                        <UserMenu
                          absPath={this.props.absPath}
                          basePath={this.props.basePath}
                        />
                      </div>
                    </div>
                    <div className="text-left branding">
                      <Logo />
                    </div>
                  </div>
                  {isAmexBanner({
                    app: this.props.app,
                    apiKey: this.props.apiKey,
                  }) && this.renderAmexBanner()}
                  {chase.bannerVisible({
                    app: this.props.app,
                    apiKey: this.props.apiKey,
                  }) && <ChaseBanner />}
                  <div className="mt1">
                    <div className="float-right">
                      <LangSwitcher />
                    </div>
                    <Nav
                      basePath={this.props.basePath}
                      app={this.props.app}
                      apiKey={this.props.apiKey}
                      translations={translations}
                    />
                  </div>
                  {error ? (
                    <div
                      className="notice error animated fadeInDownBig"
                      onClick={() => this.setState({ error: false })}
                    >
                      <a className="notice__close" href="#">
                        <i className="icon icon-close icon--white icon--sm"></i>
                      </a>
                      <p className="small m0">{error}</p>
                    </div>
                  ) : null}
                  {productGroups && productGroups.length > 0 ? (
                    productGroups.map(
                      ({
                        groupKey,
                        timeslotGroup,
                        dateTimes,
                        index,
                        products,
                      }) => {
                        const cartValidForGroup = this.isCartGroupMaxUsageValid(
                          groupKey,
                          timeslotGroup
                        );
                        const limitReached = this.isLimitReachedInGroup(
                          groupKey,
                          timeslotGroup
                        );
                        const selectedTimeslotId =
                          this.getSelectedTimeslotId(groupKey);
                        const ticketsInCartInTimeslot =
                          this.ticketsInCartByTimeslotId(
                            timeslotGroup,
                            selectedTimeslotId
                          );
                        const ticketsInCartInGroup =
                          this.ticketsInCartByGroup(groupKey);
                        const timeslotRequired = timeslotRequiredMap[groupKey];
                        const nextGroupKey = this.findNextGroupKey(
                          productGroups,
                          groupKey
                        );
                        const nextTimeslotRequired = nextGroupKey
                          ? timeslotRequiredMap[nextGroupKey]
                          : false;
                        return (
                          <div
                            className={`group-counter-row ${
                              timeslotRequired || nextTimeslotRequired
                                ? ""
                                : "divider-hidden"
                            } ${cartValidForGroup ? "" : "invalid"}`}
                            key={index}
                          >
                            {products.map((product) => {
                              return this.renderProductInfo(
                                product,
                                groupKey,
                                timeslotGroup,
                                ticketsInCartInTimeslot,
                                ticketsInCartInGroup
                              );
                            })}
                            {timeslotRequired &&
                              this.renderTimeslotSelector(
                                groupKey,
                                timeslotGroup,
                                dateTimes,
                                limitReached
                              )}
                          </div>
                        );
                      }
                    )
                  ) : (
                    <>
                      <p>
                        {
                          translations[
                            "No content found or the link has expired"
                          ]
                        }
                      </p>
                    </>
                  )}
                  <div className="flex justify-between items-center entrance-next-step my2 overflow-auto">
                    {cartValid ? (
                      <p className="small m0 text-uppercase">
                        {!isZeroTotal(total) && (
                          <>
                            {translations["Total"]}:{" "}
                            <span className="totalTicketValue">
                              {formatPriceCurrency(total)}
                            </span>
                          </>
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                    {cartValid ? (
                      <span
                        className="btn btn--round btn--cta-next m0"
                        onClick={() => this._showModal()}
                      >
                        {translations["Next step"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              { isAdmissionTicketsPage({app: this.props.app, apiKey: this.props.apiKey}) && <AdmissionTicketsInfo/> }
              <FooterNav
                basePath={this.props.basePath}
                app={this.props.app}
                apiKey={this.props.apiKey}
                translations={translations}
              />
              {isAmexSponsorship({
                app: this.props.app,
                apiKey: this.props.apiKey,
              }) && this.renderAmexLine()}
              {chase.sponsorshipVisible({
                app: this.props.app,
                apiKey: this.props.apiKey,
              }) && <ChaseSponsorship />}
              <div className="row center-xs middle-xs">
                <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6 m-mb4">
                  <TicketInfo app={this.props.app} apiKey={this.props.apiKey} />
                </div>
              </div>
            </>
          )}
          {this.state.showCheckoutModal && (
            <CheckoutModal
              apiKey={this.props.apiKey}
              basePath={this.props.basePath}
              fbPurchase={this.getFbPurchase()}
              app={this.props.app}
              translations={translations}
              total={this._totalWorth()}
              cart={cart}
              timeslots={timeslots}
              productGroups={productGroups}
              hide={() => this._hideModal()}
              onSuccess={this.success.bind(this)}
            />
          )}
        </div>
      </>
    );
  }

  async success({ response, deliveryMethod }) {
    // this.setState({loading: true})
    await this.resetAll();
    // this.setState({loading: false})
  }

  async resetAll() {
    this._emptyCart();
    this.resetTimeslots();
    await this.loadInventory();
  }

  resetTimeslots() {
    this.setState({
      timeslots: {},
      dates: {},
    });
  }

  findNextGroupKey(productGroups, groupKey) {
    const { result: nextGroupKey } = productGroups.reduce(
      (acc, { groupKey: gk }) => {
        const { current, result } = acc;
        if (!result) {
          return { current: gk, result: groupKey == current ? gk : null };
        } else {
          return acc;
        }
      },
      { current: null, result: null }
    );
    return nextGroupKey;
  }

  getProductToGroupKeys(productGroups) {
    return productGroups.reduce((acc, group) => {
      const productToGroupKeys = Object.fromEntries(
        group.products.map(({ id }) => {
          return [id, group.groupKey];
        })
      );
      return {
        ...acc,
        ...productToGroupKeys,
      };
    }, {});
  }

  getTimeslotGroupToProductIds(products) {
    return products.reduce((acc, product) => {
      const {
        ticket_type: { timeslot_group_id } = { timeslot_group_id: null },
        id,
      } = product;
      return {
        ...acc,
        [timeslot_group_id]: [...(acc[timeslot_group_id] || []), id],
      };
    }, {});
  }

  getCartGroupKeys() {
    const cartProductIds = this.getCartProductIds();
    const { productToGroupKeys } = this.state;
    return cartProductIds.map((productId) => productToGroupKeys[productId]);
  }

  ticketsInCartByTimeslotId(timeslotGroup, timeslotId) {
    if (!timeslotGroup) {
      return null;
    }
    if (!timeslotId) {
      return null;
    }

    const { cart, timeslotGroupToProductIds, productToGroupKeys } = this.state;
    const productIds = timeslotGroupToProductIds[timeslotGroup.id];

    return cart
      .filter((p) => {
        // we select only products that are linked to timeslot group and has the same timeslot selected
        if (!productIds.includes(p.id)) {
          return false;
        }
        const groupKey = productToGroupKeys[p.id];
        const productTimeslotId = this.getSelectedTimeslotId(groupKey);
        if (productTimeslotId != timeslotId) {
          return false;
        }
        return true;
      })
      .reduce((acc, { ticket_quantity }) => acc + (ticket_quantity || 1), 0);
  }

  ticketsInCartByGroup(groupKey) {
    const { cart, groupToProductIds } = this.state;
    const productIds = groupToProductIds[groupKey];

    return cart
      .filter((p) => productIds.includes(p.id))
      .reduce((acc, { ticket_quantity }) => acc + (ticket_quantity || 1), 0);
  }

  isLimitReachedInGroup(groupKey, timeslotGroup) {
    if (!timeslotGroup) {
      return false;
    }
    const selectedTimeslotId = this.getSelectedTimeslotId(groupKey);
    if (selectedTimeslotId) {
      // timeslot selected - lets check that total number of products in this timeslot is within the timeslot left_usages limit
      const leftUsages = this.getLeftUsagesByTimeslotId(
        timeslotGroup,
        selectedTimeslotId
      );
      const amountInCart = this.ticketsInCartByTimeslotId(
        timeslotGroup,
        selectedTimeslotId
      );
      return leftUsages !== null && amountInCart >= leftUsages;
    } else {
      // timeslot not selected.. we check that total number of products within current group fits max possible limit
      const maxLeftUsages =
        this.getMaxPossibleLeftUsagesByTimeslotGroup(timeslotGroup);
      const amountInCart = this.ticketsInCartByGroup(groupKey);
      return maxLeftUsages !== null && amountInCart >= maxLeftUsages;
    }
    // const ticketsInCart = this.ticketsInCartByTimeslotGroup(timeslotGroup);
    // const leftUsages = this.getLeftUsagesInSelectedTimeslot(groupKey, timeslotGroup);
    // return leftUsages !== null && ticketsInCart >= leftUsages;
  }

  isCartGroupAvailableForPurchase(groupKey, timeslotGroup) {
    if (!timeslotGroup) {
      // non-timesloted products should always stay valid, because their availability limit is fixed and enforced
      return true;
    }
    const timeslot = this.getSelectedTimeslot(groupKey);
    if (timeslot) {
      if (!this.isCartAvailableForGroupAndTimeslot(groupKey, timeslot)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Checks if the total number of tickets of the group is within the timeslot max usage limits
   * @param groupKey
   * @param timeslotGroup
   * @returns {boolean}
   */
  isCartGroupMaxUsageValid(groupKey, timeslotGroup) {
    if (!timeslotGroup) {
      return true;
    }
    const timeslotId = this.getSelectedTimeslotId(groupKey);
    if (timeslotId) {
      const leftUsages = this.getLeftUsagesByTimeslotId(
        timeslotGroup,
        timeslotId
      );
      const amountInCart = this.ticketsInCartByTimeslotId(
        timeslotGroup,
        timeslotId
      );
      return leftUsages === null || amountInCart <= leftUsages;
    } else {
      // timeslot not selected.. we check that total number of products within current group fits max possible limit
      const maxLeftUsages =
        this.getMaxPossibleLeftUsagesByTimeslotGroup(timeslotGroup);
      const amountInCart = this.ticketsInCartByGroup(groupKey);
      return maxLeftUsages === null || amountInCart <= maxLeftUsages;
    }
    // const amountInCart = this.ticketsInCartByTimeslotGroup(timeslotGroup);
    // const leftUsages = this.getLeftUsagesInSelectedTimeslot(groupKey, timeslotGroup);
    // return leftUsages === null || amountInCart <= leftUsages;
  }

  /**
   * Checks if the number of products in cart is within the available_for_purchase limits
   * @param groupKey
   * @param timeslot
   * @returns {*}
   */
  isCartAvailableForGroupAndTimeslot(groupKey, timeslot) {
    return this.getProductsInCartByGroup(groupKey).reduce((valid, product) => {
      if (!valid) {
        return valid;
      }
      const maxAvail = ProductHelper.getMaxAvailableOn(
        product,
        timeslot.timeslot_date
      );
      const productsInCart = this._amountInCart(product);
      return maxAvail === null || productsInCart <= maxAvail;
    }, true);
  }

  getLeftUsagesInSelectedTimeslot(groupKey, timeslotGroup) {
    if (!timeslotGroup) {
      return null;
    }
    const timeslotId = this.getSelectedTimeslotId(groupKey);
    if (timeslotId) {
      return this.getLeftUsagesByTimeslotId(timeslotGroup, timeslotId);
    } else {
      // no timeslot selected - lets return the maximum of the left timeslots
      return this.getMaxPossibleLeftUsagesByTimeslotGroup(timeslotGroup);
    }
  }

  getMaxPossibleLeftUsagesByTimeslotGroup(timeslotGroup) {
    if (!timeslotGroup) {
      return null;
    }
    const leftUsages = timeslotGroup.timeslots
      .map(({ left_usages }) => left_usages)
      .filter((left_usages) => left_usages != null);
    return leftUsages.length == 0 ? null : Math.max(...leftUsages);
  }

  getTimeslotById(timeslotGroup, timeslotId) {
    if (!timeslotGroup || !timeslotId) {
      return null;
    }
    return timeslotGroup.timeslots.find(({ id }) => id == timeslotId);
  }

  getLeftUsagesByTimeslotId(timeslotGroup, timeslotId) {
    const timeslot = this.getTimeslotById(timeslotGroup, timeslotId);
    return timeslot ? timeslot.left_usages : null;
  }

  isCartValidForAllGroups() {
    const { productGroups } = this.state;
    return productGroups
      .map(({ groupKey, timeslotGroup }) => {
        if (!this.isCartGroupMaxUsageValid(groupKey, timeslotGroup)) {
          return false;
        }
        if (!this.isCartGroupAvailableForPurchase(groupKey, timeslotGroup)) {
          return false;
        }
        return true;
      })
      .every((i) => i == true);
  }

  getTimeAvailDescription(left_usages) {
    const { translations } = this.state;
    if (left_usages === null) {
      return "";
    }
    if (left_usages <= 0) {
      return translations["Sold out"];
    } else if (left_usages <= 4) {
      return translations["Few left"];
    } else {
      return ""; //translations["Available"];
    }
  }

  static stringToDate(s) {
    return s ? new Date(s + "T00:00:00") : null;
  }

  static dateToString(date) {
    if (date instanceof Date) {
      return [
        ("" + date.getFullYear()).padStart(4, "0"),
        ("" + (date.getMonth() + 1)).padStart(2, "0"),
        ("" + date.getDate()).padStart(2, "0"),
      ].join("-");
    } else {
      return date;
    }
  }

  renderDatePicker(groupKey, dateTimes) {
    const { translations } = this.state;
    const dates = this.getGroupDates(dateTimes).map((s) =>
      Cart.stringToDate(s)
    );
    const CustomInput = ({ value, onClick }) => (
      <button onClick={onClick} className={value ? "" : "placeholder"}>
        {value || translations["Select date"]}
      </button>
    );
    const WrappedCustomInput = React.forwardRef((props, ref) => {
      return <CustomInput {...props} forwardedRef={ref} />;
    });
    return (
      <DatePicker
        includeDates={dates}
        locale={this.getDatePickerLocale()}
        placeholderText={translations["Select date"]}
        selected={Cart.stringToDate(this.getSelectedDate(groupKey))}
        dateFormat="yyyy-MM-dd"
        onChange={(date) => this.selectDate(groupKey, dateTimes, date)}
        disabledKeyboardNavigation
        popperPlacement="bottom-start"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "0px, 0px",
          },
        }}
        customInput={<WrappedCustomInput />}
      />
    );
  }

  getDatePickerLocale() {
    return Locale.getShortLocale();
  }

  _groupProductsByTimeGroups(products) {
    return products.reduce((acc, product) => {
      const key = getProductGroupKey(product);
      acc[key] = [...(acc[key] || []), product];
      return acc;
    }, {});
  }

  _getTimeslotGroupIdsFromProducts(products) {
    return [
      ...new Set(
        products
          .map(
            ({
              ticket_type: { timeslot_group_id } = { timeslot_group_id: null },
            }) => timeslot_group_id
          )
          .filter((i) => i != null)
          .map((i) => parseInt(i))
      ),
    ];
  }

  getGroupProductIds(groupKey) {
    const { groupToProductIds } = this.state;
    if (!groupKey in groupToProductIds) {
      return [];
    }
    return groupToProductIds[groupKey];
  }

  getGroupToProductIds(productGroups) {
    return productGroups.reduce((acc, group) => {
      return {
        ...acc,
        [group.groupKey]: group.products.map(({ id }) => id),
      };
    }, {});
  }

  groupKeyHasTimeslotGroup(groupKey) {
    const { timeslot_group_id } = parseProductGroupKey(groupKey);
    return timeslot_group_id != null;
  }

  /*
  formatDiscountsLine(discounts, timeslotGroupId) {
    const {translations} = this.state;
    if (!discounts || discounts.length==0) {
      return null;
    }
    const {products, timeslotGroupToProductIds} = this.state;
    const timeslotGroup_ProductIdToNames = products.reduce((acc, {id, name}) => {
      if (timeslotGroupToProductIds[timeslotGroupId].includes(id)) {
        return {
          [id]: name,
          ...acc,
        }
      } else {
        return acc;
      }
    }, {});

    const timeslotGroup_productIds = Object.keys(timeslotGroup_ProductIdToNames).map(i => parseInt(i));

    const isAllProductIds = (productIds) => {
      if (productIds.length!=timeslotGroup_productIds.length) {
        return false;
      }
      return productIds.find( id => !(productIds.includes(id)) )==null;
    }

    const productNames = (productIds) => {
      return productIds.map( productId => timeslotGroup_ProductIdToNames[productId] );
    }

    return discounts.filter(({percent}) => percent != 0).map(({percent, product_ids}) => {
      return ""
        // + (isAllProductIds(product_ids) ? "" : productNames(product_ids).join(", ") + " ")
        + formatPercent(percent);
    }).join(" " + translations["or"] + " ");
  }
  */

  calcProductPrice(product) {
    const timeslot = this.getSelectedTimeslot(this.getProductGroupKey(product));
    return ProductHelper.calcProductPrice(product, timeslot);
  }

  cachedProductPriceRange(product, selectedDate, evalFunc) {
    const productPriceRangeCacheKey = `${product.id}:${selectedDate || "*"}`;
    if (!(productPriceRangeCacheKey in this.priceRangesCache)) {
      this.priceRangesCache[productPriceRangeCacheKey] = evalFunc();
    }
    return this.priceRangesCache[productPriceRangeCacheKey];
  }

  formatCurrentProductPriceRange(product) {
    const productGroupKey = this.getProductGroupKey(product);
    let selectedDate = null,
      timeslot = null;
    if (this.isTimeslotRequiredForGroup(productGroupKey)) {
      selectedDate = this.getSelectedDate(productGroupKey);
      timeslot = this.getSelectedTimeslot(productGroupKey);
    }
    return this.formatProductPriceRange(product, timeslot, selectedDate);
  }

  formatProductPriceRange(product, timeslot, selectedDate = null) {
    const productGroupKey = this.getProductGroupKey(product);

    if (timeslot) {
      const productPrice = ProductHelper.calcProductPrice(product, timeslot);
      return formatPriceInCurrencyRange(productPrice);
    } else {
      // we don't know the timeslot
      const productGroup = this.getProductGroupByKey(productGroupKey);
      if (productGroup.timeslotGroup) {
        // linked to a timeslot group
        return this.cachedProductPriceRange(product, selectedDate, () => {
          let timeslots;
          if (selectedDate) {
            // get the range for this date
            timeslots = productGroup.timeslotGroup.timeslots.filter(
              ({ timeslot_date }) => timeslot_date == selectedDate
            );
          } else {
            if (config.defaultToCurrentDateRange()) {
              timeslots = productGroup.timeslotGroup.timeslots.filter(
                ({ timeslot_date }) =>
                  timeslot_date == Cart.dateToString(new Date())
              );
              if (timeslots.length == 0) {
                timeslots = productGroup.timeslotGroup.timeslots;
              }
            } else {
              timeslots = productGroup.timeslotGroup.timeslots;
            }
          }
          const prices = timeslots.map((timeslot) =>
            ProductHelper.calcProductPrice(product, timeslot)
          );

          return formatPriceInCurrencyRange(...prices);
        });
      } else {
        // product without a timeslot group - lets return the normal product price
        return formatPriceCurrency(product.price);
      }
    }
  }

  getProductGroupKey({ id }) {
    const { productToGroupKeys } = this.state;
    return productToGroupKeys[id];
  }

  formatCurrentProductsPrices(products, timeslotGroup, timeslotId) {
    // return products.map( product => {
    //   const price = this.formatProductPriceRange(product, this.getTimeslotById(timeslotGroup, timeslotId));
    //   return `${price}${config.currency.label}`;
    // }).join(" ");
    const productPrices = products.map((product) => {
      const timeslot = this.getTimeslotById(timeslotGroup, timeslotId);
      return ProductHelper.calcProductPrice(product, timeslot);
    });
    const nonZeroPrices = productPrices.filter(
      (price) => Math.abs(price - 0) > Number.EPSILON
    );
    return formatPriceInCurrencyRange(...nonZeroPrices);
  }

  getProductIdsInCartByGroup(groupKey) {
    const productIdsInCart = this.getProductIdsInCart();
    return this.getGroupProductIds(groupKey).filter((id) =>
      productIdsInCart.includes(id)
    );
  }

  getProductsInCartByGroup(groupKey) {
    const { products } = this.state;
    const selectedProductIds = this.getProductIdsInCartByGroup(groupKey);
    return selectedProductIds.map((selectedProductId) =>
      products.find(({ id }) => id == selectedProductId)
    );
  }

  getProductIdsInCart() {
    const { cart } = this.state;
    return [...new Set(cart.map(({ id }) => id))];
  }

  getProductSlugsInCart() {
    const { cart } = this.state;
    return [...new Set(cart.map(({ slug }) => slug))];
  }

  getFbPurchase() {
    return {
      value: this._totalWorth(),
      currency: config.currency.code,
      content_name: "Checkout",
      content_category: this.props.app,
      content_ids: this.getProductSlugsInCart(),
      num_items: this._totalAmountInCart(),
    };
  }

  renderAmexBanner() {
    return (
      <div className="amex__banner">
        <img src={Amex_Banner} />
      </div>
    );
  }

  renderAmexLine() {
    return (
      <>
        <div className="amex__line">
          <div className="row center-xs middle-xs">
            <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6">
              <div className="inner">
                <span>Lead Exhibition Partner</span>
                <div className="image">
                  <img src={Amex_Line} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Cart;
export { Terms } from "./terms";
