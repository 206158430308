import {Component} from "react";
// import {AccessCodes} from "./api";
// import AccessCode from "./api/accessCode";

export default class AccessCodeInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addAccessCode: props.expanded === true,
      accessCode: null,
      accessCodeText: "",
    };
  }

  async verify() {
    try {
      this.props.onError(null);
      // const response = await AccessCodes.validateAccessCode(this.state.accessCodeText, this.props.apiKey);
      // AccessCode.canApply(response?.accessCode, {cart: this.props.cart, baseTotal: this.props.baseTotal});
      //
      const accessCode = this.state.accessCodeText;
      this.setState({
        accessCode: accessCode,
        addAccessCode: false,
        accessCodeText: "",
      });
      this.props.onAccessCodeChanged(accessCode)
    } catch (e) {
      // const message = e.response?.data?.data?.error || e.message || "AccessCode validation failed";
      // this.props.onError(message);
      // this.setState({
      //   accessCode: null,
      //   addAccessCode: false,
      //   accessCodeText: "",
      // });
      // this.props.onAccessCodeChanged(null)
    }
  }

  render() {
    const {addAccessCode, accessCode} = this.state;
    const {translations} = this.props;
    return <div>
      {addAccessCode && !accessCode &&
        this.renderForm(translations)
      }
      {!addAccessCode && !accessCode &&
        this.renderButton(translations)
      }
      {accessCode &&
        this.renderAccessCode(translations)
      }
    </div>;
  }

  remove() {
    this.setState({
      accessCode: null,
    });
    this.props.onAccessCodeChanged(null)
  }

  renderButton(translations) {
    return <>
      <span className="btn"
                 onClick={() => this.setState({addAccessCode: true})}>{translations['Add access code']}</span>
      {(this.props.buttonInstructions===undefined || this.props.buttonInstructions==true) &&
          <>{this.renderMessage({styles: {marginTop: "-8px"}, translations})}</>
        }
    </>;
  }

  renderForm(translations) {
    return <div className="accessCode-input mt4 mb4">
      <p className="small mb1 text--h6 text--dark text--uppercase">{translations['Access code']}</p>
      <input type="text" name="access_code" className="text-input block fill-width mb1"
             placeholder={translations['Enter your access code']} value={this.state.accessCodeText || ""}
             onChange={(e) => this.setState({accessCodeText: e.target.value})}
             onKeyDown={(e) => {
               if (e.key === 'Enter') {
                 e.preventDefault();
                 this.verify()
               }
             }}/>
      {this.renderMessage({styles: {}, translations})}

      <button className={"btn btn--cta" + (this.state.accessCodeText == "" ? " disabled" : "")} type="button"
              onClick={() => this.verify()}
              disabled={this.state.accessCodeText == ""}>{translations['Apply']}</button>
      <button className="btn" type="button"
              onClick={() => this.setState({addAccessCode: false})}>{translations['Cancel']}</button>
    </div>;
  }

  renderAccessCode(translations) {
    const {accessCode} = this.state;
    return <div className="accessCode-input">
      <p className="small mb1">
        <span className="mr1">{translations['Access code']}</span>
        <span className="code mr3">{accessCode}</span>
        <button type="button" className="btn" onClick={() => this.remove()}>{translations['Remove']}</button>
        {(this.props.accessCodeInstructions===undefined || this.props.accessCodeInstructions==true) &&
          <>{this.renderMessage({styles: {marginTop: "-8px"}, translations})}</>
        }
      </p>
      {/*<div className="mt2 mb2">
        AccessCode: <span className="code">{accessCode}</span>
      </div>*/}
      {/*<div>
        <button type="button" className="btn" onClick={() => this.remove()}>Remove</button>
      </div>*/}
    </div>;
  }

  renderMessage({styles, translations}) {
    const {accessCodeInstructions = null} = this.props;
    console.log(accessCodeInstructions)
    return <>
        {accessCodeInstructions ? <>{accessCodeInstructions}</> : <p className="text--b2 mb4" style={styles}>{translations['access_code_instructions']}</p>}
      </>;
  }
}