import Cookies from 'js-cookie';
import wp_globals from "./wp_globals";


class Locale {

  static getShortLocale() {
    return this.getLocale().substring(0,2);
  }

  static getAvailableBranchLocales() {
    const locales = {
      "stockholm": {
        "sv-SE": "SV",
        "en-US": "EN"
      },
      "new_york": {
        "en-US": "EN"
      }
    }
    return locales;
  }

  static getAvailableLocales() {
    return Object.keys(this.getAvailableBranchLocales()[process.env.REACT_APP_BRANCH]);
  }

  static getLocale() {
    const fgPreferredLocale = this.getCookieKey();
    if (Cookies.get(fgPreferredLocale)) {
      return Cookies.get(fgPreferredLocale);
    }
    return wp_globals.config.timber_context.site.language;
  }

  static savePreferredLocale(locale) {
    Cookies.set(this.getCookieKey(), locale);
  }

  static getCookieKey() {
    const branch = process.env.REACT_APP_BRANCH || "_branch_";
    return `fg_preferred_${branch}_locale`;
  }
}

export default Locale;