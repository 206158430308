const chase = {
  isChaseBoundlessPage({app}) {
    return false;
    // return app === this.getPath();
  },
  isChaseApp({apiKey}) {
    return false;
    // return (process.env.REACT_APP_CHASE_API_KEYS || []).includes(apiKey);
  },
  infoVisible(props) {
    return false;
    // return this.isChaseApp(props) && this.isChaseBoundlessPage(props);
  },
  infoAdmissionVisible(props) {
    return false;
    // return this.isChaseApp(props) && !this.isChaseBoundlessPage(props);
  },
  sponsorshipVisible(props) {
    return false;
    // return this.isChaseBoundlessPage(props);
  },
  bannerVisible(props) {
    return false;
    // return this.isChaseApp(props);
  },
  getPath() {
    return "chasemarriottbonvoy_boundless_hiphop";
  },
  isAccessCodeRequired({app}) {
    return app === this.getPath();
  },
  translations: {
    "Enter your access code": "Enter the access code",
    "access_code_instructions": "Please enter the Chase Marriott Bonvoy® access code to unlock cardmember benefits.",
    "error": "Transactions through this webpage are for Chase Marriott Bonvoy® Cardmembers only. Please use the main ticketing webpage.",
  }
}
export default chase;