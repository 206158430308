import Cookies from 'js-cookie';
import {config} from "../js/config";


class Auth {

  static getToken() {
    return Cookies.get(config.auth.cookie_token);
  }

  static isLoggedIn() {
    return Auth.getToken() != null && Auth.getUser() != null;
  }

  static getUser() {
    try {
      return JSON.parse(Cookies.get(config.auth.cookie_user));
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  static logout() {
    Cookies.remove(config.auth.cookie_token, {path: '/', domain: config.auth.cookie_domain});
    Cookies.remove(config.auth.cookie_user, {path: '/', domain: config.auth.cookie_domain});
  }

}

export default Auth;