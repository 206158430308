import React from 'react';
import ReactDOM from 'react-dom';


import "./css/app.scss";

import Cart, {Terms} from './js/index';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Switch, useParams, withRouter} from "react-router-dom";
import wp_globals from "./utils/wp_globals";
import {FacebookPixel} from "./utils/facebookPixel";
import {GTagAds} from "./utils/gtagAds";

import TagManager from 'react-gtm-module'
import { DataLayerVar } from 'utils/dataLayerVar';
import Auth from 'utils/auth';
import { dataLayerVariable } from 'utils/dataLayer';
import { config } from 'js/config';
import { encodeSHA256 } from './utils/dataLayer';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import DetectLanguage from 'js/detectLanguage';

if (process.env.REACT_APP_GTM) {
  TagManager.initialize({gtmId: process.env.REACT_APP_GTM || ''})
}


const SelectedApp = withRouter(({app = null, apiKey = null, basePath = "/", productId = null, history}) => {
  if (!apiKey) {
    if (!(app in wp_globals.config.timber_context.api_keys)) {
      app = 'default';
      basePath = `/${productId || ''}`;
      history.push(basePath);
    }
    apiKey = wp_globals.config.timber_context.api_keys[app];
  } else {
    app = 'hidden';
  }
  const absPath = `${window.location.protocol}//${window.location.host}${basePath}`;
  return (
    <>
      <Cart app={app} absPath={absPath} basePath={basePath} apiKey={apiKey} filterProductIds={productId ? [productId] : null}/>
    </>
  );
});

function SelectedAppFromParams(props) {
  const params = useParams();
  const productId = 'productId' in params ? params.productId : null;
  const productSuffix = productId ? `/products/${productId}` : '';
  if ('app' in params) {
    const {app} = params;
    return SelectedApp({app, basePath: `/${app}${productSuffix}`, productId});
  } else if ('apiKey' in params) {
    const {apiKey} = params;
    return SelectedApp({apiKey, basePath: `/secret/${apiKey}${productSuffix}`, productId});
  } else {
    return SelectedApp({
      app: 'default', ...props,
      productId,
      basePath: (productSuffix.length > 0 ? productSuffix : '/')
    });
  }
}

async function getDataLayerVars() {
  // Used by Snap Pixel
  let vars = {
    item_category: "Tickets",
    currency: config.currency.code.toUpperCase(),
  };

  if (Auth.isLoggedIn()) {
    vars = {
      ...vars,
      user_email: Auth.getUser().email || null,
      user_phone_number: Auth.getUser().phone ? Auth.getUser().phone : null,
      user_hashed_email: await encodeSHA256(Auth.getUser().email),
      user_hashed_phone_number: await encodeSHA256(Auth.getUser().phone),
      uuid_c1: Auth.getUser().id,
    }
  }
  return vars;
}

const matomoConfigs = {
  stockholm: {
    siteId: '5',
    configurations: {
      setCookieDomain: ".fotografiska.com",
      setDomains: [
        "stockholm.fotografiska.com",
        "shop-stockholm.fotografiska.com",
        "tickets-sto.fotografiska.com",
        "members-sto.fotografiska.com",
      ],
      enableCrossDomainLinking: true,
      trackPageView: true,
      enableLinkTracking: true,
    },
  },
  new_york: {
    siteId: '4',
    configurations: {
      setCookieDomain: ".fotografiska.com",
      setDomains: [
        "newyork.fotografiska.com",
        "shop-newyork.fotografiska.com",
        "tickets-ny.fotografiska.com",
        "members-ny.fotografiska.com",
      ],
      enableCrossDomainLinking: true,
      trackPageView: true,
      enableLinkTracking: true,
    },
  },
};


const matomoOpts = {
  urlBase: 'https://fotografiska.matomo.cloud/',
  // userId: undefined, // optional, default value: `undefined`.
  // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: process.env.MATOMO_SITE_ID == '', // optional, false by default. Makes all tracking calls no-ops if set to true.
  // heartBeat: { // optional, enabled by default
  //   active: true, // optional, default value: true
  //   seconds: 10 // optional, default value: `15
  // },
  // linkTracking: false, // optional, default value: true
  ...(matomoConfigs[process.env.REACT_APP_BRANCH] || {}),
}
const matomoInstance = createInstance(matomoOpts)


if (document.getElementById('ticket-container')) {
  getDataLayerVars().then((vars) => {
    ReactDOM.render(
      <React.StrictMode>
        <MatomoProvider value={matomoInstance}>
          <Router>
            <DetectLanguage/>
            <FacebookPixel/>
            <GTagAds {...vars}/>
            <Switch>
              <Route path="/terms">
                <Terms/>
              </Route>
              <Route path={["/secret/:apiKey/products/:productId", "/secret/:apiKey"]} children={<SelectedAppFromParams/>}/>
              <Route path={["/products/:productId"]} children={<SelectedAppFromParams app="default"/>}/>
              <Route path={["/:app/products/:productId"]} children={<SelectedAppFromParams/>}/>
              <Route path={["/:app"]} children={<SelectedAppFromParams/>}/>
              <Route path={["/"]} children={<SelectedAppFromParams app="default"/>}/>
            </Switch>
          </Router>
        </MatomoProvider>
      </React.StrictMode>,
      document.getElementById('ticket-container')
    );
  })
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
