import {get as getProperty} from 'lodash';
import wp_globals from './wp_globals';

const GLOBALS = wp_globals || {};

// if (process.env.NODE_ENV !== 'production') {
//   console.log('wp_globals => ', wp_globals);
// }


export function get(...args) {
  return getProperty(GLOBALS, ...args);
}

