import React from "react";
import {ReactComponent as Logo} from "../images/logo.svg";
import {Link} from "react-router-dom";

export class NyTerms extends React.Component {
  render() {
    return <>
      <div className="row center-xs middle-xs py2 px1">
        <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6">
          <div className="text-center">
            <Link to="/"><Logo/></Link>
          </div>
          <div className="content my2 text-left">
            <h3 className="mb5">TERMS OF USE</h3>

            <p>A Fotografiska NY ticket is a revocable license and may be taken up and admission refused upon refunding the purchase price. The resale at a higher price than the original sale price is not permissible.</p>

            <p>The holder of a ticket voluntarily assumes all risk and danger incidental to the event for which the ticket is issued whether occurring prior to, during or after the same. Your admission to Fotografiska New York constitutes your agreement to be photographed, filmed, videotaped or otherwise recorded in connection with this establishment and your agreement that your name, voice and likeness may be broadcasted, distributed, or displayed in connection with any program, promotion or advertisement created from this event worldwide, in perpetuity, without any compensation being paid to you.</p>

            <p>In the case of cancellation of the event any loss or inconvenience experienced by any holder shall be limited to refund of printed price on the ticket.  Tickets if lost or stolen cannot be replaced.</p>

            <h5>Notice of Filming & Photography</h5>
            <p>By attending this event, you are entering an area where photography, audio, and video recording may occur.</p>

            <p>By agreeing to these terms, you consent to photography, audio recording, video recording and its release, publication, exhibition, or reproduction to be used for promotional purposes, advertising, inclusion on websites, social media, or any other purpose by Fotografiska New York, their partners, and their representatives. You release Fotografiska New York, their employees, partners, and each and all persons involved from any liability connected with the taking, recording, digitizing, or publication and use of interviews, photographs, computer images, video and/or recordings, and you waive all rights to any claims for payment or royalties in connections with any use of these materials. You also waive any right to inspect or approve any photo, video, or audio recording taken by Fotografiska New York  or the person or entity designated to do so by Fotografiska New York.</p>


            <h5>Privacy and Security Policy</h5>

            <p><strong>Disclosure of Personal Information:</strong></p>
            <p>Fotografiska New York is committed to keeping your personal information strictly confidential. Only the organization of the performance for which tickets were purchased will retain your information. When we collect your personal information (i.e. name, address, e-mail, and telephone number) it is in an effort to improve your shopping experience and to notify you about program updates, services and answer inquiries. Beyond the presenting organization for your event it is our policy not to disclose any of your personal information at any time, unless you tell us to do so. We do not sell, trade or rent your personal information to third parties. From time to time, we'll share aggregated (collective) information about our members as a group, or generate aggregate reports and market research for business partners. These reports will not identify individual members.</p>

            <p><strong>Cookies:</strong></p>
            <p>Our site uses cookies to personalize and improve your shopping experience. These cookies do not contain any confidential or personal information. They allow us to enhance your shopping experience at Fotografiska New York. For example, if you have added products to your shopping cart and suddenly have to log out of our site, you can return later that day and still have those items in your shopping cart.</p>

            <p><strong>Security:</strong></p>
            <p>We use industry-standard methods to protect your information from unauthorized access. Among other techniques, we store such information on a computer behind our "firewall" in a secure location, and we restrict the number of employees internally who can access such data. To ensure the safety of your personal information, including your credit card number, we use Secure Socket Layer (SSL) Technology. SSL protects information as it crosses the Internet. Any data stored is kept in a secure off-site location and stored in a password-protected server. Please be aware, of course, that there is no such thing as perfect security - on or off the Internet.</p>

            <p>Please keep security in mind as you reach other websites through links from Fotografiska New York. Each separate company has its own privacy policies and practices and may market to you accordingly. Although we choose our business partners carefully, we can make no representations concerning privacy and security on those sites.</p>

            <p>Your password is your key to your account. You should never share it with anyone and we request that you change it periodically and often. You can change your password at any time by accessing My Account on our home page. If you can't remember your password, the reminder phrase you entered when you last changed or set-up your password will be e-mailed to you immediately. You may also contact us at <a href="mailto:hello.ny@fotografiska.com">hello.ny@fotografiska.com</a> for additional help. We strongly urge you to sign off your account and close your browser window when you have finished your shopping to prevent someone else from accessing your account.</p>

            <p><strong>Changing Personal Information:</strong></p>
            <p>You may at any time modify or change any information you have previously provided to us by clicking on "My Account", then choose the appropriate section to edit, review, or delete your personal information. Changes will be updated immediately. You may also contact us at <a href="mailto:hello.ny@fotografiska.com">hello.ny@fotografiska.com</a> and we will be happy to change, modify or delete any information for you.</p>

            <p><strong>Changes in Policy:</strong></p>
            <p>If you have any concerns or questions with respect to our Privacy Policy, please feel free to contact us at <a href="mailto:hello.ny@fotografiska.com">hello.ny@fotografiska.com</a>. By using this site, you are agreeing to the conditions of this Privacy Policy. DO NOT USE THIS SITE IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY. If we decide to change our Privacy Policy in whole or in part it will be posted on the site. Please check this page periodically for changes.</p>


          </div>
        </div>
      </div>
    </>;
  }
}