import React from "react";
import {branchedComponent} from "../utils/branch";
import {string} from "../utils/translate";
import Locale from "../utils/locale";

function LangSwitcherNYC(props) {
  return <span>
  </span>;
}

function LangSwitcherSTO(props) {
  const switchLocale = (locale) => {
    Locale.savePreferredLocale(locale);
    window.location.reload();
  }
  const locale = Locale.getLocale();
  const locales = {
    'sv-SE': "SV",
    'en-US': "EN",
  }
  const otherLocale = Object.keys(locales).find(i => i!=locale);
  const otherLocaleName = locales[otherLocale];
  return <>
    <a className="btn" onClick={() => switchLocale(otherLocale)}>{otherLocaleName}</a>
  </>;
}
export const LangSwitcher = branchedComponent({stockholm: LangSwitcherSTO, new_york: LangSwitcherNYC});

