import {roundPrice} from "../util";

export default class Coupon {

  static canApply(coupon, purchase) {
    if (!coupon) {
      return true;
    }
    this.validateMinPurchaseAmount(coupon, purchase);
    this.validateRestrictedProducts(coupon, purchase);
    this.validateMinQuantity(coupon, purchase);
    this.validateTimeslots(coupon, purchase);
    return true;
  }

  static validateMinPurchaseAmount(coupon, {baseTotal}) {
    if (coupon.minimum_purchase_amount !== null && baseTotal < coupon.minimum_purchase_amount) {
      throw Error(`Code cannot be used for orders below ${coupon.minimum_purchase_amount}`);
    }
  }

  static validateMinQuantity(coupon, {groups}) {
    if (coupon.min_quantity !== null) {
      const quantity = groups.reduce((total, {items}) => {
        return total + items.reduce((t, {
          product: {id},
          quantity
        }) => t + (this.matchesCouponProducts(coupon, id) ? quantity : 0), 0);
      }, 0);

      if (quantity < coupon.min_quantity) {
        throw Error(`Code is valid only if ${coupon.min_quantity} or more products are purchased at once`);
      }
    }
  }

  static validateTimeslots(coupon, {groups}) {
    const valid = groups.find(({timeslot, items}) => {
      return items.find(({product: {id}}) => this.matchesCoupon(id, timeslot, coupon)) !== undefined;
    }) !== undefined;
    if (!valid) {
      throw Error(`Code is not valid for the selected date and time`);
    }
  }


  static validateRestrictedProducts(coupon, purchase) {
    if (coupon.product_ids != null && coupon.product_ids.length > 0) {
      const productIds = purchase.getProductIds();
      if (productIds.find(i => this.matchesCouponProducts(coupon, i)) === undefined) {
        throw Error(`Code cannot be applied to this purchase`);
      }
    }
  }

  static matchesCoupon(productId, timeslot, coupon) {
    return this.matchesCouponProducts(coupon, productId)
      && this.matchesCouponTimes(coupon, timeslot)
      && this.matchesCouponTimeslotRange(coupon, timeslot);
  }


  static matchesCouponProducts(coupon, productId) {
    return coupon.product_ids === null || coupon.product_ids.length == 0 || coupon.product_ids.includes(productId);
  }

  static matchesCouponTimes(coupon, timeslot) {
    if (coupon.times !== null && coupon.times.length > 0) {
      const matches = coupon.times.find((time) => this.timeslotMatchesTime(timeslot, time)) !== undefined;
      if (!matches) {
        return false;
      }
    }
    return true;
  }

  static matchesCouponTimeslotRange(coupon, timeslot) {
    if (coupon.timeslot_since!==null || coupon.timeslot_until!==null) {
      if (timeslot == null) {
        // coupon can be applied only at the specific time range, but no timeslot is needed – accept coupon
        return true;
      }
      const timeslotTime = `${timeslot.timeslot_date} ${timeslot.timeslot_start}`;
      return (coupon.timeslot_since===null || timeslotTime>=coupon.timeslot_since) &&
        (coupon.timeslot_until===null || timeslotTime<=coupon.timeslot_until);
    }
    return true;
  }

  static parseTime(time) {
    const parts = time.split(' ');
    if (parts.length==2) {
      return parts;
    } else if (parts.length == 1) {
      return [parts[0], null];
    } else {
      return [null, null];
    }
  }

  static timeslotMatchesTime(timeslot, time) {
    if (timeslot == null) {
      // coupon can be applied only at the specific time, but no timeslot is needed – accept coupon
      return true;
    }
    const [d, t] = this.parseTime(time);
    return d == timeslot.timeslot_date && (t == null || t == timeslot.timeslot_start);
  }
}