import { useLocation } from "react-router-dom";
import Locale from "utils/locale";

export default function DetectLanguage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get("lang");
  if (lang) {
    const locales = Locale.getAvailableLocales();
    const foundLocale = locales.find((locale) => locale.startsWith(lang));
    if (foundLocale!==undefined && foundLocale!==Locale.getLocale())   {
      Locale.savePreferredLocale(foundLocale);
      window.location.reload();
    }
  }
  return <></>;
}
