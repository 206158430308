import {ProductHelper} from "../product";
import {roundPrice, roundToPlaces} from "../util";
import Coupon from "./coupon";
import {round} from "lodash/math";

class LineItemGroup {

  constructor({index, timeslot, items}) {
    this.index = index;
    this.timeslot = timeslot;
    this.items = items;
  }

  getProductIds() {
    return [...new Set(this.items.reduce((acc, {product: {id}}) => [...acc, id], []))];
  }

}

export class LineItem {

  constructor({product, quantity, basePrice, baseTotal}) {
    this.product = product;
    this.quantity = quantity;
    this.basePrice = basePrice;
    this.price = basePrice;
    this.baseTotal = baseTotal;
    this.total = this.baseTotal;
  }

  applyPercentDiscount(percent) {
    this.total = roundToPlaces(Math.max(0, this.baseTotal * (1.0 - percent/100.0)), 5);
    this.price = roundPrice(this.total / this.quantity);
  }

  applyUnitDiscount(discount) {
    this.total = roundToPlaces(Math.max(0, this.baseTotal - discount * this.quantity), 5);
    this.price = roundPrice(this.total / this.quantity,5);
  }
}

export class Purchase {
  constructor({ cart, timeslots, productGroups, coupon = null }) {
    this.groups = this.buildSummaryGroups({ cart, timeslots, productGroups });
    this.baseTotal = this.calcBaseTotal();
    this.total = this.applyCoupon(coupon);
    this.couponDiscount = this.calcCouponDiscount();
  }

  buildSummaryGroups({ cart, timeslots, productGroups }) {
    return productGroups
      .map(({ timeslotGroup, groupKey, index, products }) => {
        const timeslotId =
          groupKey && groupKey in timeslots ? timeslots[groupKey] : null;
        const timeslot = timeslotId
          ? timeslotGroup.timeslots.find(({ id }) => id == timeslotId)
          : null;
        return new LineItemGroup({
          index,
          timeslot: timeslot,
          items: products
            .map((product) => {
              const quantity = cart.filter(({ id }) => id == product.id).length;
              const basePrice = ProductHelper.calcProductPrice(
                product,
                timeslot
              );
              const baseTotal = quantity * basePrice;
              return new LineItem({
                product,
                quantity,
                basePrice,
                baseTotal,
              });
            })
            .filter(({ quantity }) => quantity > 0),
        });
      })
      .filter(({ items }) => items.length > 0);
  }

  calcBaseTotal() {
    return this.groups.reduce((total, { items }) => {
      return total + items.reduce((t, { baseTotal }) => t + baseTotal, 0);
    }, 0);
  }

  calcTotal() {
    return this.groups.reduce((total, { items }) => {
      return total + items.reduce((t, { total }) => t + total, 0);
    }, 0);
  }

  applyCoupon(coupon) {
    this.coupon = coupon;
    if (!coupon) {
      return this.baseTotal;
    }

    switch (coupon.discount_type) {
      case "percent":
        for (let group of this.groups) {
          for (let index in group.items) {
            let item = group.items[index];
            if (Coupon.matchesCoupon(item.product.id, group.timeslot, coupon)) {
              item.applyPercentDiscount(coupon.discount);
            }
          }
        }
        return this.calcTotal();
      case "purchase_amount":
        const totalAfterDiscount = Math.max(
          0,
          this.baseTotal - coupon.discount
        );
        const discountPercent =
          ((this.baseTotal - totalAfterDiscount) / this.baseTotal) * 100.0;
        for (let group of this.groups) {
          for (let index in group.items) {
            group.items[index].applyPercentDiscount(discountPercent);
          }
        }
        return this.calcTotal();
      case "unit_amount":
        for (let group of this.groups) {
          for (let index in group.items) {
            const item = group.items[index];
            if (Coupon.matchesCoupon(item.product.id, group.timeslot, coupon)) {
              group.items[index].applyUnitDiscount(coupon.discount);
            }
          }
        }
        return this.calcTotal();
      default:
        // throw Error(`Unknown coupon discount type`);
        return this.baseTotal;
    }
  }

  calcCouponDiscount() {
    return this.baseTotal - this.total;
  }

  getProductIds() {
    return [
      ...new Set(
        this.groups.reduce(
          (acc, group) => [...acc, ...group.getProductIds()],
          []
        )
      ),
    ];
  }

  getTotalQuantity() {
    return this.groups.reduce(
      (acc, { items }) =>
        acc + items.reduce((t, { quantity }) => t + quantity, 0),
      0
    );
  }
}
