import {config} from "./config";

export function formatTimeslotDate(timeslot_date) {
  return timeslot_date;
}

export function formatTimeslotTimeRange({timeslot_start, timeslot_end}) {
  const startLabel = formatTimeslotTime(timeslot_start);
  const endLabel = formatTimeslotTime(timeslot_end);
  if (startLabel == endLabel) {
    return startLabel;
  } else if (startLabel!==null && endLabel!==null) {
    if (config.timeslot.format == "range") {
      return `${startLabel} – ${endLabel}`;
    } else {
      return startLabel;
    }
  } else if (startLabel!==null) {
    return startLabel;
  } else {
    return endLabel;
  }
}

function pad(num, size) {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

function formatTimeslotTime(timeslot_time) {
  if (timeslot_time == null) {
    return timeslot_time;
  }
  const match = /^([\d]{1,2}):([\d]{1,2}):([\d]{1,2})$/i.exec(timeslot_time);
  if (match) {
    let [hour, minute, second] = match.slice(1).map(i => parseInt(i));
    let dayOffset = 0;
    if ((second + 1) == 60) {
      // add 1 second to format HH:59:59 as (HH+1):00:00
      const seconds = hour * 3600 + minute * 60 + second + 1;
      hour = Math.floor(seconds/3600);
      minute = Math.floor((seconds - hour * 3600)/60);
      second = seconds - hour * 3600 - minute * 60;
      if (hour >= 24) {
        hour %= 24; // next day
      }
    }
    if (config.timeslot.timeFormat == "12h") {
      const suffix = hour <= 11 ? "AM" : "PM";
      const hour12 = hour == 0 ? 12 : (hour > 12 ? hour - 12 : hour);
      return `${hour12}:${pad(minute,2)} ${suffix}`;
    } else {
      return `${hour}:${pad(minute,2)}`;
    }
  } else {
    return timeslot_time;
  }
}


